import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { faPlus } from "@fortawesome/pro-light-svg-icons";

import { faRocket } from "@fortawesome/pro-regular-svg-icons";

import { useNavigate } from "react-router-dom";

import { Icon, PermissionCheck } from "@/components";

import { PermissionRoles } from "@/enum";

import { Button } from "@/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { CoursesTable } from "../../components/CoursesTable";
import { CreateCoursesModal } from "../../components/CreateCoursesModal";
import StartCourseModal from "../../components/StartCourseModal";
import { Courses } from "../../schema/courses";

export const CoursesOverview: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);
	const navigate = useNavigate();
	const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
	const [isStaringCourseModalOpen, setIsStaringCourseModalOpen] = useState<boolean>(false);
	const [selectedTrainingInfo, setSelectedTrainingInfo] = useState<Courses>();

	const onStartTrainingHandler = (trainingInfo: Courses) => {
		setSelectedTrainingInfo(trainingInfo);
		setIsStaringCourseModalOpen(true);
	};

	useEffect(() => {
		if (!isStaringCourseModalOpen) {
			setSelectedTrainingInfo(undefined);
		}
	}, [isStaringCourseModalOpen]);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{ts("title")}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
				<div className="flex justify-end gap-2">
					<PermissionCheck requiredPermissions={[PermissionRoles.MANAGE_COURSES]}>
						<Button
							className="h-[40px]"
							color={ButtonColor.PRIMARY}
							image={
								<div className="mr-2 flex items-center justify-center">
									<Icon className="w-4 h-4" icon={faRocket} />
								</div>
							}
							size={ButtonSize.S}
							testId="new-user-button"
							title={ts("start.title")}
							onClick={() => setIsStaringCourseModalOpen(true)}
						/>
					</PermissionCheck>
					<PermissionCheck requiredPermissions={[PermissionRoles.MANAGE_COURSES]}>
						<Button
							className="h-[40px]"
							color={ButtonColor.ACTION_SECONDARY}
							image={
								<div className="mr-2 flex items-center justify-center">
									<Icon className="w-4 h-4" icon={faPlus} />
								</div>
							}
							size={ButtonSize.S}
							testId="new-user-button"
							title={ts("create.button")}
							onClick={() => navigate(`/app/courses/create`)}
						/>
					</PermissionCheck>
				</div>
			</div>

			<CoursesTable onStartTrainingHandler={onStartTrainingHandler} />

			{isCreateModalOpen && (
				<CreateCoursesModal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} />
			)}
			{isStaringCourseModalOpen && (
				<StartCourseModal
					isOpen={isStaringCourseModalOpen}
					setIsOpen={setIsStaringCourseModalOpen}
					trainingInfo={selectedTrainingInfo}
				/>
			)}
		</div>
	);
};
