/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@mui/material";

import { faArrowsMaximize, faArrowsMinimize } from "@fortawesome/pro-regular-svg-icons";

import { Dispatch, SetStateAction } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "@/components";

import { Button } from "@/components/Button/Button";

import Chat from "./ChatComponents/Chat";
import { ScenarioActionTypes } from "../enums/ScenarioActionTypes.enum";
import { ScenarioMessageTypes } from "../enums/ScenarioMessageTypes.enum";
import { ScenarioMessageInfo } from "../interfaces/ScenarioMessageInfo.interface";

interface IProtocolProps {
	sendProtocol: (type: ScenarioMessageTypes, data: any) => void;
	updateProtocol: (type: ScenarioMessageTypes, messageId: string, data: unknown) => void;
	protocolMessages: ScenarioMessageInfo[];
	responsibleForEmergencyId: number;
	isExpandedProtocolOpen: boolean;
	setIsExpandedProtocolOpen: Dispatch<SetStateAction<boolean>>;
	sendScenarioAction: (type: ScenarioActionTypes, data: unknown) => void;
	currentPhaseId: number;
	isLastPhase: boolean;
}

export default function Protocol({
	sendProtocol,
	updateProtocol,
	protocolMessages,
	responsibleForEmergencyId,
	isExpandedProtocolOpen,
	setIsExpandedProtocolOpen,
	sendScenarioAction,
	currentPhaseId,
	isLastPhase,
}: IProtocolProps) {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);

	return (
		<div
			className={`flex flex-col h-full ${isExpandedProtocolOpen ? "max-h-[80vh]" : "max-h-[30vh]"}`}
		>
			<div className="flex justify-between items-center pb-6 border-b-[1px] border-[#EAECF0]">
				<Typography
					gutterBottom
					sx={{ fontWeight: "600", fontSize: "24px", color: "#101828", mb: "0px" }}
					variant="h4"
				>
					{ts("progress.protocol")}
				</Typography>

				<Button
					className="bg-white !w-5 !h-5 !p-0"
					image={
						<Icon
							className="text-[#363F72] w-5 h-5"
							icon={isExpandedProtocolOpen ? faArrowsMinimize : faArrowsMaximize}
						/>
					}
					title=""
					onClick={() => setIsExpandedProtocolOpen((prev) => !prev)}
				/>
			</div>
			<div
				className={`flex-1 flex flex-col w-full gap-4 bg-[#F9FAFB] ${
					isExpandedProtocolOpen ? "max-h-[61vh]" : "max-h-[25vh]"
				}`}
			>
				<Chat
					isProtocol
					chatBodyClassName="!border-[0px]"
					currentPhaseId={currentPhaseId}
					handleAction={updateProtocol}
					isLastPhase={isLastPhase}
					messages={protocolMessages}
					responsibleForEmergencyId={responsibleForEmergencyId}
					sendMessage={(message) => sendProtocol(ScenarioMessageTypes.MESSAGE, { message })}
					sendScenarioAction={sendScenarioAction}
				/>
			</div>
		</div>
	);
}
