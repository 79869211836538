import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/system";
import { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { useCreateCoursesMutation } from "@/pages/Private/redux/courses/courses.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { InputField } from "@/components/InputField/InputField";

import { Button } from "@/components/Button/Button";

import { BackLink } from "@/components/BackLink/BackLink";

import { ButtonColor } from "@/components/Button/types";

import { EMPTY_COURSE_SCENARIO } from "../constants/emptyCourseScenario";
import { CreateCourses, CreateCoursesSchema } from "../schema/courses";
import CustomJsonEditor from "./CustomJsonEditor";
import FilesUploader, { IFileInfo } from "./FilesUploader";

export const CreateCoursesForm: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);
	const navigate = useNavigate();
	const [createCourses, { isLoading, error }] = useCreateCoursesMutation();
	const [currentFormState, setCurrentFormState] = useState<Partial<CreateCourses>>({
		name: undefined,
		json: EMPTY_COURSE_SCENARIO,
	});
	const [files, setFiles] = useState<IFileInfo[]>([]);

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateCourses>({
		defaultValues: {
			name: undefined,
			json: EMPTY_COURSE_SCENARIO,
		},
		resolver: zodResolver(CreateCoursesSchema),
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateCourses) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onSubmit = async (values: CreateCourses) => {
		try {
			const result = await createCourses({
				...values,
				json:
					typeof currentFormState.json === "string"
						? JSON.parse(currentFormState.json || "{}")
						: currentFormState.json || {},
				courseFiles: files?.length
					? files.map((fileInfo) => ({
							id: fileInfo.id,
							fullFilePath: fileInfo.fullFilePath,
							fileName: fileInfo.fileName,
							fileLength: fileInfo.fileLength,
							fileType: fileInfo.fileType,
					  }))
					: [],
			}).unwrap();

			if (result?.id) {
				navigate("/app/courses/edit/" + result.id, { replace: true });
			}
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const handleClose = () => {
		navigate(-1);
	};

	return (
		<div className="sm:block w-full flex flex-col items-start justify-between mb-6 pb-8">
			<BackLink />
			<div className="flex flex-col justify-between mb-6 w-full">
				<div className="flex items-end justify-between w-full mb-8">
					<div className="flex-grow">
						<h1 className="mb-3">{ts("create.title")}</h1>
					</div>
				</div>
				<div className="flex flex-row w-full bg-brand-white p-6 gap-8 border border-gray-100 rounded-md">
					<Stack spacing={2} width={"50%"}>
						<InputField
							error={!!errors.name?.message}
							handleChange={(e) => handleSaveFormState(e.target.value, "name")}
							label={ts("name")}
							name={"name"}
							placeholder={ts("name")}
							value={currentFormState?.name || ""}
						/>
						<CustomJsonEditor
							value={currentFormState?.json ?? EMPTY_COURSE_SCENARIO}
							onChange={(value) => handleSaveFormState(value, "json")}
						/>
					</Stack>

					<Stack width={"50%"}>
						<FilesUploader files={files} setFiles={setFiles} />
					</Stack>
				</div>

				<Stack
					alignItems="center"
					direction="row"
					flexWrap="wrap"
					justifyContent={"flex-end"}
					marginTop={3}
					spacing={2}
				>
					<div className="w-fit">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title={t("basics.cancel")}
							onClick={handleClose}
						></Button>
					</div>
					<div className="w-fit">
						<Button
							color={ButtonColor.BRAND}
							disabled={isLoading}
							isLoading={isLoading}
							title={t("basics.save")}
							onClick={handleSubmit(onSubmit)}
						></Button>
					</div>
				</Stack>
				{getAllErrors(error, formErrors).length ? (
					<Box sx={{ mt: 2 }}>{renderErrorMessages(getAllErrors(error, formErrors))}</Box>
				) : null}
			</div>
		</div>
	);
};
