import { FunctionComponent } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { classNames } from "@/utils/classNames";

import { useNavigationGetter } from "@/constants/sidenav.config";

import { useHasPermissions } from "@/utils/useHasPermissions";

import { authSelector, setAuth } from "@/pages/Public/redux/auth.slice";

import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { ROUTE_CONFIG } from "@/routes/config";

import { User } from "@/pages/Public/pages/Login/schema/login";

import { ReactComponent as ArrowRightStartOnRectangle } from "@assets/icons/topbar/arrow-right-start-on-rectangle.svg";

import { ReactComponent as UserIcon } from "@assets/icons/topbar/user.svg";

import { SidebarItem } from "../SidebarItem/SidebarItem";

import { ContextMenu } from "../ContextMenu/ContextMenu";
import { SvgIcon } from "../Icon/SvgIcon";
import { Logo } from "../Logo/Logo";

const { ACCOUNT, COURSES } = ROUTE_CONFIG;

export const Sidebar: FunctionComponent = () => {
	const { t } = useTranslation();

	const ts = (key: string) => t(`sidebar.${key}`);

	const { hasPermissions } = useHasPermissions();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { user } = useAppSelector(authSelector);

	const NavItemConfig = useNavigationGetter();

	const handleNavigateAccount = () => {
		navigate(ACCOUNT);
	};

	const handleNavigateHome = () => {
		navigate(COURSES);
	};

	const initialUserAsString = localStorage.getItem("initialUser");

	let initialUser: { user: User } | null = null;

	if (initialUserAsString) {
		initialUser = JSON.parse(initialUserAsString) as { user: User };
	}

	const resetInitialUser = () => {
		dispatch(setAuth(initialUser));
		localStorage.removeItem("initialUser");
	};

	const items = NavItemConfig.ALL;

	return (
		<div
			className={classNames(
				"bg-white",
				"sm:z-[21] flex-shrink-0 w-[82px] relative pt-0 right-0 duration-200 ease-in-out z-20 print:hidden overflow-auto bb-scrollbar flex flex-col",
				// sidebarOpen ? "translate-x-0" : "translate-x-full lg:translate-x-0",
				"h-[calc(100vh)]",
				"border-r border-gray-200"
			)}
		>
			<div className="h-screen">
				<Logo handleClick={handleNavigateHome} />
				<div className="flex flex-col justify-between h-[calc(92vh)]">
					<div className="px-[18px]">
						<div className="">
							<div className="">
								{items.upper
									.filter((item) => {
										if (item.permission) {
											return hasPermissions([item.permission]);
										} else {
											return true;
										}
									})
									.filter((item) => {
										if (item.companyIds && user?.company) {
											return item.companyIds.includes(user.company.id);
										} else {
											return true;
										}
									})
									.map((item) => (
										<SidebarItem
											key={item.path}
											companyId={user?.company?.id}
											icon={item.icon}
											iconActive={item.iconActive}
											path={item.path}
											title={item.title}
											type={item.type || "icon"}
										/>
									))}
							</div>
						</div>
					</div>
					<div className="px-[18px]">
						<div className="pb-3">
							<ContextMenu
								data={[
									[
										{
											title: "My Profile",
											icon: (
												<SvgIcon className="w-[20px] h-[20px] text-gray-700" svgIcon={UserIcon} />
											),
											onClick: () => {
												handleNavigateAccount();
											},
										},
										{
											title: initialUser ? ts("back") : ts("logout"),
											icon: (
												<SvgIcon
													className="w-[20px] h-[20px] text-gray-700"
													svgIcon={ArrowRightStartOnRectangle}
												/>
											),
											onClick: () => (initialUser ? resetInitialUser() : navigate("/logout")),
										},
									],
								]}
								position="top"
								width="150"
							>
								<div className="flex flex-row items-center w-fit ali cursor-pointer">
									<span className="w-[48px] h-[48px] text-[18px] text-center text-white rounded-full bg-primary-blue leading-[44px]">
										{user?.firstName?.[0]}
										{user?.lastName?.[0]}
									</span>
								</div>
							</ContextMenu>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
