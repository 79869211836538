/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

import { Icon } from "@/components";
import { Button } from "@/components/Button/Button";

import BlockTitle from "./BlockTitle";
import ChatDecision from "./MessageTypes/ChatDecision";
import ChatMessage from "./MessageTypes/ChatMessage";
import EndScenarioMessage from "./MessageTypes/EndScenarioMessage";
import TimeUpMessage from "./MessageTypes/TimeUpMessage";
import { ScenarioActionTypes } from "../../enums/ScenarioActionTypes.enum";
import { ScenarioMessageTypes } from "../../enums/ScenarioMessageTypes.enum";
import { ScenarioMessageInfo } from "../../interfaces/ScenarioMessageInfo.interface";

interface IChatProps {
	handleAction?: (type: ScenarioMessageTypes, messageId: string, data: unknown) => void;
	sendMessage: (message: string) => void;
	messages: ScenarioMessageInfo[];
	chatBodyClassName?: string;
	isProtocol?: boolean;
	responsibleForEmergencyId?: number;
	sendScenarioAction?: (type: ScenarioActionTypes, data: unknown) => void;
	currentPhaseId?: number;
	isLastPhase?: boolean;
}

export default function Chat({
	messages,
	handleAction,
	sendMessage,
	chatBodyClassName,
	isProtocol = false,
	responsibleForEmergencyId,
	sendScenarioAction,
	currentPhaseId,
	isLastPhase,
}: IChatProps) {
	const [errorMessage, setErrorMessage] = useState("");
	const [message, setMessage] = useState("");

	const onMessageInput = (event: ChangeEvent<{ value: string }>) => {
		if (event?.target?.value) {
			if (errorMessage) {
				setErrorMessage("");
			}
		}

		setMessage(event.target.value);
	};

	const handleMessageSend = () => {
		if (message && message?.length) {
			sendMessage(message);
			setErrorMessage("");
			setMessage("");
		} else {
			setErrorMessage("Can't send empty field");
		}
	};

	return (
		<>
			<div
				className={`flex-1 w-full flex flex-col overflow-auto custom-scrollbar items-center p-6 bg-[#F9FAFB] border-b-[1px] border-[#EAECF0] ${chatBodyClassName}`}
			>
				{messages?.length ? (
					<div className="px-2 flex flex-col mt-auto w-full flex-1">
						{/* {<BlockTitle label="Today" />} */}
						{messages?.map((messageInfo, indx) => {
							if (messageInfo.type === ScenarioMessageTypes.MESSAGE) {
								const isTheSameUser =
									indx !== 0 && messages[indx - 1].data?.userId === messageInfo?.data?.userId;
								const isLessThanOneMinute =
									indx !== 0 && messageInfo.timestamp - messages[indx - 1].timestamp < 60000;

								return (
									<ChatMessage
										key={messageInfo.id}
										isProtocol={isProtocol}
										isStacked={isTheSameUser && isLessThanOneMinute}
										messageInfo={messageInfo}
									/>
								);
							} else if (messageInfo.type === ScenarioMessageTypes.DECISION) {
								return (
									<ChatDecision
										key={indx}
										currentPhaseId={currentPhaseId ?? -1}
										decisionInfo={messageInfo.data}
										isLastPhase={!!isLastPhase}
										messageId={messageInfo.id}
										responsibleForEmergencyId={responsibleForEmergencyId ?? -1}
										sendScenarioAction={sendScenarioAction ?? console.log}
										timestamp={messageInfo?.timestamp}
										onActionClick={handleAction ?? console.log}
									/>
								);
							} else if (messageInfo.type === ScenarioMessageTypes.END) {
								return (
									<EndScenarioMessage
										key={indx}
										responsibleForEmergencyId={responsibleForEmergencyId ?? -1}
										sendScenarioAction={sendScenarioAction ?? console.log}
										timestamp={messageInfo?.timestamp}
									/>
								);
							} else if (messageInfo.type === ScenarioMessageTypes.TIMEUP) {
								return (
									<TimeUpMessage
										key={indx}
										leftTimeInSeconds={messageInfo.data?.leftTime}
										timestamp={messageInfo.timestamp}
									/>
								);
							}
						})}
					</div>
				) : (
					<p className="flex-1 flex items-center justify-centers w-full">
						{<BlockTitle label="No messages yet" />}
					</p>
				)}
			</div>
			<div className="flex gap-4">
				<div className="w-full">
					<TextField
						fullWidth
						FormHelperTextProps={{
							sx: {
								fontSize: "0.825rem",
								color: "#FF0000",
							},
						}}
						InputProps={{
							sx: {
								fontSize: "1rem",
								color: "#101828",
								fontWeight: "400",
								lineHeight: "1.5rem",
								"&:focus-visible": {
									outline: "none",
									inset: "none",
									border: "none",
								},
								paddingY: "8px",
								"& input": {
									paddingY: "8px",
								},
								"&:focus": {
									outline: "none",
									boxShadow: "none",
									inset: "none",
									border: "none",
								},
								backgroundColor: "#fff",
							},
						}}
						error={!!errorMessage}
						helperText={errorMessage}
						placeholder="Message"
						sx={{
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									border: "1px solid #D0D5DD",
								},
								"&:hover fieldset": {
									border: "1px solid #3E478470",
								},
								"&.Mui-focused fieldset": {
									border: "none",
									outline: "none",
									inset: "none",
								},
								mb: !!errorMessage ? "-2px" : "20px",
							},
						}}
						value={message}
						onChange={onMessageInput}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								handleMessageSend();
							}
						}}
					></TextField>
				</div>
				<Button
					className="!w-[50px] h-[50px]"
					image={<Icon className="w-4 h-4" icon={faPaperPlane} />}
					title=""
					onClick={() => {
						handleMessageSend();
					}}
				></Button>
			</div>
		</>
	);
}
