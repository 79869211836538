import { useAppSelector } from "@/redux/hooks";

import { formatTimestamp } from "@/utils/formatTimestamp";

import UserIcon from "../../UserIcon";
import { ScenarioMessageInfo } from "../../../interfaces/ScenarioMessageInfo.interface";

interface IChatMessageProps {
	messageInfo: ScenarioMessageInfo;
	isStacked: boolean;
	isProtocol: boolean;
}

export default function ChatMessage({
	messageInfo,
	isStacked,
	isProtocol = false,
}: IChatMessageProps) {
	const currentUserId = useAppSelector((state) => state.auth?.user?.id ?? -1);
	const isMyOwnMessage = messageInfo.data?.userId === currentUserId;

	const messageContainerStyles = !isProtocol
		? isMyOwnMessage
			? "bg-[#3E4784] text-white"
			: "bg-white text-[#101828] border-[#EAECF0] border-[1px]"
		: "bg-[#F2F4F7] text-[#101828]";
	const borderRadiusStyles = isMyOwnMessage
		? "rounded-tl-lg rounded-br-lg rounded-bl-lg"
		: "rounded-tr-lg rounded-br-lg rounded-bl-lg";
	const messageTextColor = !isProtocol && isMyOwnMessage ? "text-white " : "text-[#101828]";

	return (
		<div
			key={messageInfo.data?.id}
			className={`w-full ${isMyOwnMessage ? "pl-6 space-y-[6px]" : "flex gap-3"} ${
				!isStacked ? "mt-4" : "mt-1"
			}`}
		>
			{!isMyOwnMessage && (
				<UserIcon
					firstName={messageInfo.data?.firstName}
					isHidden={isStacked}
					lastName={messageInfo.data?.lastName}
				/>
			)}
			<div className={`flex-1 ${isStacked ? "space-y-[2px]" : "space-y-[6px]"}`}>
				{!isStacked && (
					<div className="flex justify-between px-1">
						<p className="text-[0.875rem] text-[#344054] font-medium">
							{isMyOwnMessage
								? "You"
								: messageInfo.data?.firstName + " " + messageInfo.data?.lastName}
						</p>
						<p className="text-[0.75rem] text-[#475467]">
							{formatTimestamp(messageInfo.timestamp)}
						</p>
					</div>
				)}
				<div className={`${messageContainerStyles} px-[14px] py-[10px] ${borderRadiusStyles}`}>
					<p className={`text-[1rem] ${messageTextColor}`}>{messageInfo.data?.message}</p>
				</div>
			</div>
		</div>
	);
}
