import { CourseProgressEnum } from "@/enum/course-progress.enum";

export default {
	translation: {
		companies: {
			title: "Company Management",
			subtitle: "Manage your teams and user permissions.",
			search: "Search for company",
			name: "Name",
			linkedinUrl: "LinkedIn URL",
			users: "Users",
			website: "Website",
			create: {
				button: "Add New Company",
				title: "Add New Company",
			},
			delete: {
				title: "Delete Company",
			},
			update: {
				title: "Update Company",
			},
		},
		company: {
			title: "Company Management",
			subtitle: "Manage your team members and their account permissions here.",
			tabs: {
				general: "General",
				user: "Users",
				roles: "User roles",
			},
			companyInformation: "Company Information",
			autoInvite: "Auto Invite to your company linkedin page",
			apolloApiKey: "Apollo API Key",
			lemlistApiKey: "Lemlist API Key",
		},
		courses: {
			title: "Scenarios",
			subtitle: "Manage your scenarios.",
			search: "Search for scenario",
			name: "Name",
			users: "Users",
			json: "JSON Input",
			create: {
				button: "Create New Scenario",
				title: "Create New Scenario",
			},
			delete: {
				title: "Delete Scenario",
			},
			update: {
				title: "Update Scenario",
				text: "Text",
				json: "JSON",
			},
			upload: {
				title: "Click to upload or drag and drop",
				description: "(up to 20 MB)",
				drop: "Drop file here",
			},
			start: {
				title: "Start Scenario",
				label: "Select scenario to start",
				"please-select": "Please select scenario",
				"start-training-description":
					'Do you really want to start scenario with name "{{trainingName}}"?',
				"invite-colleagues-title": "Invite team",
				"invite-colleagues-description": "Invite colleagues to collaborate on this scenario.",
			},
			progress: {
				protocol: "Protocol",
				next: "Next Phase",
				save: "Save decision",
				complete: "Complete the scenario",
				"time-up":
					"The time is running out, be careful, there are only {{leftTime}} minutes left to complete this stage",
				"time-up-empty":
					"The time for completing the stage is running out, pay attention to the timer",
			},
			end: {
				[CourseProgressEnum.Success]:
					'You have successfully completed the scenario: "{{scenarioName}}"',
				[CourseProgressEnum.Failed]: 'Scenario "{{scenarioName}}" has not been completed',
				success: "Congratulations!",
				"time-up": "The time allotted for the stage was exhausted",
				"no-info": "No information on the result of the scenario is available",
			},
		},
		course: {
			title: "Scenario Management",
			subtitle: "Manage your Scenario.",
			tabs: {
				general: "General",
				user: "Users",
				roles: "User roles",
			},
			courseInformation: "Scenario Information",
		},
		dashboard: {
			title: "Welcome",
		},
		sidebar: {
			users: "Users",
			roles: "User roles",
			logout: "Logout",
			accounts: "Accounts",
			settings: "Settings",
			accountSettings: "Account Settings",
			profile: "My Profile",
			companies: "Company Management",
			back: "Back to your account",
			all: "All",
			courses: "Scenarios",
			cybersecurityIncidentBasics: "Cybersecurity Incident Basics",
		},
		errors: {
			requiredFields: "Not all necessary fields are filled.",
			expectedNumber: "Number expected / enter 0 if not needed.",
			customColumnAlreadyExists: "Custom column already exists.",
			invalidEmail: "Invalid email",
			invalidPassword: "Invalid password",
			passwordGuidelinesNotMet: "Password guidelines not met",
			termsAcceptanceMissing: "Terms acceptance missing",
			invalidValue: "Invalid value",
			invalidData: "Invalid data",
			userNotConfirmed: "User not confirmed",
			passwordMismatch: "Passwords do not match",
			invalidToken: "Invalid token",
			emailAlreadyTaken: "Email already taken",
			wentWrong: "Something went wrong",
			unauthorized: "Unauthorized",
			invalidAuthCode: "Invalid auth code",
			nameMissing: "Name missing",
			wrongUrlShouldBeLinkedinProfile: "Wrong URL. Should be a LinkedIn profile URL",
			notEnoughCredits: "Not enough credits",
		},
		account: {
			connectionScraping: "LinkedIn-Connections",
			enableConnectionScraping: "active the scraping of your linkedin connections",
			enableConnectionScrapingDescription:
				"This allows you to identify which prospects you or your colleagues are already connected to on Linkedin.",
			linkedinAutomation: "LinkedIn Automation",
			enableAutoAccept: "Enable Auto Accept",
			enableAutoAcceptDescription: "Automatically accept connection requests",
			profileVisits: "Profile Visits",
			warningPremiumAccountRequired: "This feature is only available for premium accounts",
			enableProfileVisitsScraping: "Enable Profile Visits Scraping",
			changePassword: "Change password",
			currentPassword: "Current password",
			disableTwoFactorAuth: "Disable 2FA",
			enableTwoFactorAuth: "Enable 2FA",
			enableTwoFactorAuthDescription: "Scan the QR code with your authenticator app",
			newPassword: "New Password",
			profileInformation: "Profile information",
			profileSecurity: "Profile security",
			repeatPassword: "Repeat new password",
			tabs: {
				general: "General",
				notifications: "Notifications",
				linkedinConnections: "LinkedIn Connections",
			},
			title: "Personal Info",
			description: "Update your personal details and settings here.",
			save: "Save changes",
			saving: "Saving...",
			cancel: "Cancel",
			linkedinConnections: "LinkedIn Connections",
		},
		basics: {
			selectColumns: "Select columns",
			wait: "Please wait",
			reset: "Reset",
			pleaseSelect: "Please select",
			noResults: "No results",
			account: "Account",
			actions: "Actions",
			admin: "Admin",
			back: "Back",
			block: "block",
			cancel: "Cancel",
			change: "Edit",
			code: "Code",
			confirmationClose: "Are you sure you want to close the window?",
			confirm: "Confirm",
			confirmPassword: "Confirm Password",
			createSomething: "Create {{something}}",
			delete: "Delete",
			description: "Description",
			dragAndDropImage: "Drag & Drop",
			edit: "Edit",
			editSomething: "Edit {{something}}",
			email: "E-mail Address",
			enterSomething: "Enter {{something}}",
			firstName: "First Name",
			home: "Home",
			label: "Label",
			lastName: "Last Name",
			linkedinUrl: "LinkedIn URL",
			logout: "Logout",
			name: "Name",
			noData: "No Data",
			password: "Password",
			permissions: "Permissions",
			resetPassword: "Reset password",
			role: "Role",
			save: "Save",
			saveChanges: "Save changes",
			search: "Search {{something}}...",
			select: "Select",
			selectSomething: "Select {{something}}",
			setPassword: "Set password",
			status: "Status",
			termsAndConditions: "<noLink>I have read the</noLink> <link1>Terms and Conditions</link1>",
			upload: "Upload",
			value: "Value",
			warning: "Attention",
			filter: "Filter",
			clear: "Reset All Filters",
			close: "Close",
			rows: "Rows",
			columns: "Columns",
			columnsShown: "Columns shown",
			export: "Export",
			next: "Next",
			previous: "Previous",
			addCriteria: "Add Criteria",
			copyCriteria: "Copy Criteria",
			archive: "Archive",
			unarchive: "Unarchive",
			"enter-your": "Enter your ",
		},
		errorPages: {
			buttonText: "Take Me Home",
			description:
				"You either tried some shady route or you came hee by mistake. Whichever it is, try using the navigation.",
			notFound: "404: The page you are looking for isn't here",
			serverError: "500: Internal Server Error",
			unauthorize: "401: Authorization required",
			forbidden: "403: Forbidden",
		},
		forgotPassword: {
			forgotPasswordCta: "Send reset link",
			submittedFeedbackMessage:
				"Check your inbox. We send an e-mail with instructions on how to reset your password!",
			title: "Forgot password",
		},
		inviteUser: {
			button: "Invite new user",
			modal: {
				cta: "Invite",
				emailPlaceholder: "Enter email",
				firstNamePlaceholder: "Enter first name",
				lastNamePlaceholder: "Enter last name",
				rolePlaceholder: "Select role",
				title: "Invite user",
			},
		},
		login: {
			emailPlaceholder: "Your e-mail address",
			forgotPasswordCta: "Forgot Password?",
			loginCta: "Login",
			passwordPlaceholder: "Your password",
			title: "Welcome back",
			description: "Welcome back! Please enter your details.",
			invalidData: "email or password wrong",
			"first-login": {
				title: "Set your Name",
				description: "This is your name that will be displayed in your scenarios.",
				continue: "Continue",
			},
			"check-email": {
				title: "Check your email",
				description: "We sent a verification link to ",
				"enter-code": "Enter code manually",
				"back-to-login": "Back to log in",
			},
		},
		modals: {
			addAvatar: {
				description: "<underline>Click to upload</underline> or drag and drop",
				fileSize: "Max file size is 3 MB",
				title: "Upload File",
			},
			deleteRole: {
				title: "Delete role",
			},
			deleteUser: {
				title: "Delete user",
			},
			editUser: {
				rolePlaceholder: "Select Roles",
				title: "Edit user",
			},
		},
		twoFA: {
			code: "Code",
			cta: "Confirm",
			title: "Enter 2FA Code",
		},
		users: {
			title: "Users",
			search: "users",
		},
		userRoles: {
			title: "Roles",
			button: "User roles",
			modal: {
				create: {
					permissionPlaceholder: "Select Permissions",
					title: "Create role",
				},
				edit: {
					title: "Update role",
				},
			},
			placeholders: {
				codePlaceholder: "Enter",
				namePlaceholder: "Enter name",
			},
		},
	},
};
