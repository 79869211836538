export enum PermissionRoles {
	CREATE_ROLE = "CREATE_ROLE",
	READ_ROLES = "READ_ROLES",
	UPDATE_ROLE = "UPDATE_ROLE",
	DELETE_ROLE = "DELETE_ROLE",
	INVITE_USER = "INVITE_USER",
	GET_USER = "GET_USER",
	GET_ME = "GET_ME",
	DELETE_USER = "DELETE_USER",
	CHANGE_PASSWORD = "CHANGE_PASSWORD",
	UPDATE_ME = "UPDATE_ME",
	UPDATE_USER = "UPDATE_USER",
	UPDATE_USER_ROLE = "UPDATE_USER_ROLE",
	GENERATE_2FA = "GENERATE_2FA",
	GET_PERMISSION = "GET_PERMISSION",
	UPDATE_PERMISSION = "UPDATE_PERMISSION",
	MANAGE_CONFIGURATION = "MANAGE_CONFIGURATION",
	BLACKLIST_JWT = "BLACKLIST_JWT",
	LOGOUT = "LOGOUT",
	DISABLE_2FA = "DISABLE_2FA",
	VERIFY_2FA = "VERIFY_2FA",
	UPLOAD_FILES = "UPLOAD_FILES",
	COOKIE = "COOKIE",
	DESKTOP = "DESKTOP",
	COMPANY = "COMPANY",
	COURSES = "COURSES",
	GLOBAL_ADMIN = "GLOBAL_ADMIN",
	ADMIN = "ADMIN",
	USER = "USER",
	MANAGE_COURSES = "MANAGE_COURSES",
}
