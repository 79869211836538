import { FunctionComponent } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useAppSelector } from "@/redux/hooks";
import { PUBLIC_PAGES_AFTER_LOGIN, ROUTE_CONFIG } from "@/routes/config";
import { AuthLayout, ErrorPage } from "@/components";
import { ErrorPageType } from "@/enum";

import { Login } from "./pages/Login/Login";
import { authSelector } from "./redux/auth.slice";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { SetPassword } from "./pages/SetPassword/SetPassword";
import { TwoFactorAuth } from "./pages/TwoFactorAuth/TwoFactorAuth";
import { ChangePassword } from "./pages/ChangePassword/ChangePassword";
import FirstLogin from "./pages/FirstLogin/FirstLogin";
import CheckYourEmail from "./pages/CheckYourEmail/CheckYourEmail";

const { TFA, COURSES } = ROUTE_CONFIG;

export const Public: FunctionComponent = () => {
	const { user, otpToken } = useAppSelector(authSelector);
	const { pathname } = useLocation();

	const publicPages = PUBLIC_PAGES_AFTER_LOGIN.find((pp: string) => pathname.includes(pp));

	if (user && !otpToken && !publicPages && pathname !== "/error-page") {
		return <Navigate to={COURSES} />;
	}

	if (user && otpToken && pathname !== TFA && pathname !== "/error-page") {
		return <Navigate to={TFA} />;
	}

	return (
		<Routes>
			<Route element={<Navigate to="/login" />} path="/" />
			<Route element={<AuthLayout />}>
				<Route element={<Login />} path="login" />
				<Route element={<TwoFactorAuth />} path="2fa" />
				<Route element={<SetPassword />} path="/set-password" />
				<Route element={<ChangePassword />} path="/change-password" />
				<Route element={<ForgotPassword />} path="/forgot-password" />
				<Route element={<FirstLogin />} path="/first-login" />
				<Route element={<CheckYourEmail />} path="/check-email" />
			</Route>
			<Route element={<ErrorPage type={ErrorPageType.NotFound} />} path="*" />
			<Route element={<ErrorPage type={ErrorPageType.Forbidden} />} path="/error-page" />
		</Routes>
	);
};
