/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { JsonData, JsonEditor } from "json-edit-react";

import { Alert } from "@mui/material";

import { z } from "zod";

import { TextArea } from "@/components/TextArea/TextArea";

import { Button } from "@/components/Button/Button";

import { ButtonColor } from "@/components/Button/types";

import { JsonSchema } from "../schema/courses";

interface IJsonEditorProps {
	value: any;
	onChange: (value: any) => void;
}

export default function CustomJsonEditor({ value, onChange }: IJsonEditorProps) {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);
	const [jsonEditor, setJsonEditor] = useState<boolean>(true);
	const [isShowActionButton, setIsShowActionButton] = useState<boolean>(false);
	const [unsavedChanges, setUnsavedChanges] = useState<string>("");
	const [unsavedJSONChanges, setUnsavedJSONChanges] = useState<JsonData>({});
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		if (value) {
			setUnsavedChanges(typeof value !== "string" ? JSON.stringify(value) : value);
			setUnsavedJSONChanges(typeof value === "string" ? JSON.parse(value) : value);
		}
	}, [value]);

	const onSaveChanges = (parsedValue?: any) => {
		try {
			const validatedScenario = JsonSchema.parse(parsedValue);

			setErrorMessage("");
			setIsShowActionButton(false);

			onChange(validatedScenario);
			if (!parsedValue) {
				setJsonEditor(!jsonEditor);
			}
		} catch (e) {
			if (e instanceof z.ZodError) {
				setErrorMessage(
					"Invalid course scenario structure: " +
						e?.errors?.map((err) => err?.path?.join("->") + " " + err?.message).join(", ")
				);
			} else {
				setErrorMessage("Invalid JSON format or structure");
			}
		}
	};

	return (
		<div className="space-y-4 w-full">
			{!jsonEditor && (
				<div className="space-y-1">
					<TextArea
						className="bg-white max-h-[40vh] overflow-auto custom-scrollbar"
						handleChange={(e) => {
							if (!isShowActionButton) {
								setIsShowActionButton(true);
							}

							setUnsavedChanges(e?.target.value);
						}}
						label={ts("json")}
						name={"json"}
						placeholder={ts("json")}
						showError={false}
						value={unsavedChanges}
					/>
					{isShowActionButton && (
						<div className="flex gap-2">
							<Button
								className=""
								color={ButtonColor.BRAND}
								title={t("basics.save")}
								onClick={() => onSaveChanges(JSON.parse(unsavedChanges))}
							/>
							<Button
								color={ButtonColor.ACTION_SECONDARY}
								title={t("basics.cancel")}
								onClick={() => {
									setUnsavedChanges(typeof value !== "string" ? JSON.stringify(value) : value);
									setErrorMessage("");
									setIsShowActionButton(false);
								}}
							/>
						</div>
					)}
				</div>
			)}

			{jsonEditor && (
				<div className="space-y-1 w-full">
					<JsonEditor
						className="bg-white w-full max-h-[50vh] overflow-auto custom-scrollbar"
						data={unsavedJSONChanges || {}}
						maxWidth={"100%"}
						rootName=""
						setData={(data: JsonData) => {
							if (!isShowActionButton) {
								setIsShowActionButton(true);
							}

							setUnsavedJSONChanges(data);
						}}
					/>
					{isShowActionButton && (
						<div className="flex gap-2">
							<Button
								className=""
								color={ButtonColor.BRAND}
								title={t("basics.save")}
								onClick={() => onSaveChanges(unsavedJSONChanges)}
							/>
							<Button
								color={ButtonColor.ACTION_SECONDARY}
								title={t("basics.cancel")}
								onClick={() => {
									setUnsavedJSONChanges(typeof value === "string" ? JSON.parse(value) : value);
									setErrorMessage("");
									setIsShowActionButton(false);
								}}
							/>
						</div>
					)}
				</div>
			)}
			{errorMessage && (
				<Alert severity="error" sx={{ alignItems: "center", bgcolor: "error.light", fontSize: 14 }}>
					{errorMessage}
				</Alert>
			)}
			<Button
				title={jsonEditor ? ts("update.text") : ts("update.json")}
				onClick={() => {
					setJsonEditor(!jsonEditor);
					setIsShowActionButton(false);
				}}
			/>
		</div>
	);
}
