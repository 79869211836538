import { useMemo } from "react";

interface IUserIconProps {
	firstName: string;
	lastName: string;
	isOnline?: boolean;
	size?: UserIconSize;
	containerClassName?: string;
	isHidden?: boolean;
}

export enum UserIconSize {
	Small = "SMALL",
	Large = "LARGE",
}

export default function UserIcon({
	firstName,
	lastName,
	isOnline = true,
	size = UserIconSize.Large,
	containerClassName = "",
	isHidden = false,
}: IUserIconProps) {
	const firstNameLetter = firstName?.[0]?.toUpperCase();
	const lastNameLetter = lastName ? lastName?.[0]?.toUpperCase() : "";

	const { statusWidth, statusHeight, iconWidth, iconHeight, textSize, padding } = useMemo(() => {
		switch (size) {
			case UserIconSize.Large:
				return {
					statusWidth: 10,
					statusHeight: 10,
					iconWidth: 40,
					iconHeight: 40,
					textSize: 16,
					padding: 8,
				};
			case UserIconSize.Small:
				return {
					statusWidth: 6,
					statusHeight: 6,
					iconWidth: 24,
					iconHeight: 24,
					textSize: 9,
					padding: 0,
				};
			default:
				return {
					statusWidth: 10,
					statusHeight: 10,
					iconWidth: 40,
					iconHeight: 40,
					textSize: 16,
					padding: 8,
				};
		}
	}, [size]);

	return (
		<p
			className={`relative text-center font-semibold rounded-full ${
				!isHidden && "bg-[#F2F4F7] text-[#475467] border-[0.75px] border-[#EAECF0]"
			} ${containerClassName}`}
			style={{
				width: `${iconWidth}px`,
				height: `${iconHeight}px`,
				fontSize: `${textSize}px`,
				padding: `${padding}px`,
			}}
		>
			{!isHidden ? (
				<>
					{firstNameLetter + lastNameLetter}
					{isOnline ? (
						<span
							className="absolute right-0 bottom-0 rounded-full"
							style={{
								width: `${statusWidth}px`,
								height: `${statusHeight}px`,
								backgroundColor: "#17B26A",
							}}
						></span>
					) : (
						<span
							className={"absolute right-0 bottom-0 rounded-full"}
							style={{
								width: `${statusWidth}px`,
								height: `${statusHeight}px`,
								backgroundColor: "#98A2B3",
							}}
						></span>
					)}
				</>
			) : (
				<></>
			)}
		</p>
	);
}
