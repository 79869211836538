import { zodResolver } from "@hookform/resolvers/zod";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Box, Stack } from "@mui/system";

import { useNavigate, useParams } from "react-router-dom";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import {
	useGetCourseQuery,
	useUpdateCoursesMutation,
} from "@/pages/Private/redux/courses/courses.api";

import { InputField } from "@/components/InputField/InputField";

import { Button } from "@/components/Button/Button";

import { BackLink } from "@/components/BackLink/BackLink";

import { ButtonColor } from "@/components/Button/types";

import { EMPTY_COURSE_SCENARIO } from "../constants/emptyCourseScenario";
import { UpdateCourses, UpdateCoursesSchema } from "../schema/courses";
import CustomJsonEditor from "./CustomJsonEditor";
import FilesUploader, { IFileInfo } from "./FilesUploader";

export const UpdateCoursesForm: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);
	const { id = 0 } = useParams();
	const navigate = useNavigate();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { data, isFetching } = useGetCourseQuery(+id);
	const [update, { isLoading, error }] = useUpdateCoursesMutation();
	const [currentFormState, setCurrentFormState] = useState<UpdateCourses>();

	const [files, setFiles] = useState<IFileInfo[]>([]);

	useEffect(() => {
		if (data) {
			const formState = {
				id: +id,
				name: data.name,
				json: Object.keys(data.json).length ? data?.json : EMPTY_COURSE_SCENARIO,
			};

			setCurrentFormState({
				...formState,
			});

			if (data?.courseFiles?.length) {
				setFiles(data?.courseFiles);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof UpdateCourses) => {
		setCurrentFormState((prevState) => ({
			...prevState,
			[name]: value,
		}));

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<UpdateCourses>({
		defaultValues: {
			name: currentFormState?.name,
			json: currentFormState?.json || EMPTY_COURSE_SCENARIO,
		},
		resolver: zodResolver(UpdateCoursesSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: UpdateCourses) => {
		try {
			await update({
				...values,
				name: values?.name ? values.name : currentFormState?.name,
				json:
					typeof currentFormState?.json !== "string"
						? JSON.stringify(currentFormState?.json || {})
						: currentFormState?.json || "{}",
				id: currentFormState?.id,
				courseFiles: files?.length
					? files.map((fileInfo) => ({
							id: fileInfo.id,
							fullFilePath: fileInfo.fullFilePath,
							fileName: fileInfo.fileName,
							fileLength: fileInfo.fileLength,
							fileType: fileInfo.fileType,
					  }))
					: [],
			}).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	const handleClose = () => {
		navigate(-1);
	};

	return (
		<div>
			<BackLink />
			<div className="flex flex-col justify-between mb-6">
				<div className="flex items-end justify-between w-full mb-8">
					<div className="flex-grow">
						<h1 className="mb-3">{ts("update.title")}</h1>
					</div>
				</div>
				<div className="flex flex-row w-full bg-brand-white p-6 gap-8 border border-gray-100 rounded-md">
					<Stack spacing={2} width={"50%"}>
						<InputField
							error={!!errors.name?.message}
							handleChange={(e) => handleSaveFormState(e.target.value, "name")}
							label={ts("name")}
							name={"name"}
							placeholder={ts("name")}
							value={currentFormState?.name || ""}
						/>
						<CustomJsonEditor
							value={currentFormState?.json ?? EMPTY_COURSE_SCENARIO}
							onChange={(value) => handleSaveFormState(value, "json")}
						/>
					</Stack>

					<Stack width={"50%"}>
						<FilesUploader files={files} setFiles={setFiles} />
					</Stack>
				</div>

				<Stack
					alignItems="center"
					direction="row"
					flexWrap="wrap"
					justifyContent={"flex-end"}
					marginTop={3}
					spacing={2}
				>
					<div className="w-fit">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title={t("basics.cancel")}
							onClick={handleClose}
						></Button>
					</div>
					<div className="w-fit">
						<Button
							color={ButtonColor.BRAND}
							disabled={isLoading}
							isLoading={isLoading}
							title={t("basics.save")}
							onClick={handleSubmit(onSubmit)}
						></Button>
					</div>
				</Stack>
				{getAllErrors(error, formErrors).length ? (
					<Box sx={{ mt: 2 }}>{renderErrorMessages(getAllErrors(error, formErrors))}</Box>
				) : null}
			</div>
		</div>
	);
};
