/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge, Box, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "@/components";
import { Button } from "@/components/Button/Button";

import useSocketConnection from "@/pages/Private/helpers/useSocketConnection";

import { useGetScenarioProgressQuery } from "@/pages/Private/redux/couse-progress/couse-progress.api";

import { ButtonColor } from "@/components/Button/types";

import { EMPTY_COURSE_SCENARIO } from "../../Courses/constants/emptyCourseScenario";
import AddTeamModal from "./AddTeamModal";
import Chat from "./ChatComponents/Chat";
import CountdownTimer from "./CountdownTimer";
import InformationTab from "./InformationTab";
import ListConnectedUsers from "./ListConnectedUsers";
import Protocol from "./Protocol";
import { ScenarioActionTypes } from "../enums/ScenarioActionTypes.enum";

export default function CourseInProgress() {
	const { t } = useTranslation();
	const { id } = useParams();
	const [tabValue, setTabValue] = useState(0);
	const navigate = useNavigate();
	const { data, isFetching } = useGetScenarioProgressQuery(id ? id : "");
	const [isExpandedProtocolOpen, setIsExpandedProtocolOpen] = useState(false);
	const {
		sendChatMessage,
		connectedUsers,
		chatMessages,
		protocolMessages,
		sendProtocolMessage,
		updateProtocolMessage,
		sendScenarioAction,
		getScenarioActualData,
		currentStage,
		currentStageEndTimestamp,
	} = useSocketConnection(id ? id : "", actionHandler);
	const [isShowAddTeamModal, setIsShowAddTeamModal] = useState(false);

	const currentCourseScenario = useMemo(() => {
		if (data?.course?.json) {
			return data?.course?.json;
		}

		return EMPTY_COURSE_SCENARIO;
	}, [data]);

	function actionHandler(receivedData: any) {
		if (!receivedData?.type) {
			return;
		}

		switch (receivedData?.type) {
			case ScenarioActionTypes.NEW_STAGE:
				break;
			case ScenarioActionTypes.FINISH_SCENARIO:
				navigate(`/app/courses/${id}/end`, {
					replace: true,
					state: {
						scenarioName: receivedData.scenarioName,
						status: receivedData.status,
						additionalStatusInfo: receivedData?.additionalStatusInfo,
					},
				});
				break;
			default:
				console.log("Received unknown action", receivedData?.type, data);
				break;
		}
	}

	useEffect(() => {
		getScenarioActualData();
	}, [getScenarioActualData]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
		setTabValue(newValue);
	};

	return (
		<>
			<div className="h-full w-full space-y-8 flex flex-col">
				<div className="w-full flex justify-between">
					<Typography
						gutterBottom
						sx={{ fontWeight: "600", fontSize: "30px", color: "#101828", mb: "0px" }}
						variant="h2"
					>
						{currentCourseScenario?.scenarioName}
					</Typography>
					<div className="flex items-center gap-3">
						<ListConnectedUsers connectedUsers={connectedUsers} />
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							image={
								<div className="mr-2 flex items-center justify-center">
									<Icon className="w-4 h-4" icon={faUserPlus} />
								</div>
							}
							title="Add team"
							onClick={() => setIsShowAddTeamModal(true)}
						></Button>
					</div>
				</div>
				<div className="flex h-[90%] justify-between w-full gap-16 flex-1">
					<div className="w-[50%] lg:w-[70%] flex flex-col gap-14">
						<div className={`space-y-5 flex-1`}>
							<div className="flex justify-between items-center">
								<Typography
									gutterBottom
									sx={{ fontWeight: "600", fontSize: "24px", color: "#101828", mb: "0px" }}
									variant="h4"
								>
									{currentCourseScenario?.Content?.[currentStage]?.phaseName}
								</Typography>
								{currentStageEndTimestamp > 0 && (
									<CountdownTimer
										key={currentStageEndTimestamp}
										currentStageEndTimestamp={currentStageEndTimestamp}
									/>
								)}
							</div>
							<div
								className={`${
									isExpandedProtocolOpen ? "h-[10vh] overflow-y-auto" : "flex-grow max-h-[45vh]"
								} overflow-auto space-y-5 custom-scrollbar`}
							>
								<Typography
									sx={{ fontWeight: "400", fontSize: "16px", color: "#475467", mb: "0px" }}
									variant="body1"
								>
									{currentCourseScenario?.Content?.[currentStage]?.content}
								</Typography>
							</div>
						</div>
						<Protocol
							currentPhaseId={currentCourseScenario?.Content?.[currentStage]?.id ?? -1}
							isExpandedProtocolOpen={isExpandedProtocolOpen}
							isLastPhase={currentStage === currentCourseScenario?.Content?.length - 1}
							protocolMessages={protocolMessages}
							responsibleForEmergencyId={data?.user?.id ?? -1}
							sendProtocol={sendProtocolMessage}
							sendScenarioAction={sendScenarioAction}
							setIsExpandedProtocolOpen={setIsExpandedProtocolOpen}
							updateProtocol={updateProtocolMessage}
						/>
					</div>
					<div className="w-[50%] lg:w-[30%] gap-4 flex flex-col p-6 min-h-full rounded-16 border border-[#EAECF0] shadow-custom-xs">
						<Tabs
							sx={{
								padding: "4px",
								borderRadius: "8px",
								backgroundColor: "#F9FAFB",
								display: "flex",
								gap: "8px",
								"& .MuiTabs-indicator": {
									display: "none",
								},
							}}
							value={tabValue}
							variant="fullWidth"
							onChange={handleTabChange}
						>
							<Tab
								label={
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											gap: chatMessages?.length < 100 ? "16px" : "20px",
										}}
									>
										Chat
										<Badge
											badgeContent={chatMessages?.length < 100 ? chatMessages?.length : "99+"}
											sx={{
												"& .MuiBadge-badge": {
													backgroundColor: "#F4F4F4",
													color: "#344054",
													fontSize: "12px",
													fontWeight: "bold",
													borderRadius: "50%",
													height: "20px",
													minWidth: "20px",
												},
											}}
										/>
									</Box>
								}
								sx={{
									textTransform: "none",
									fontWeight: "bold",
									backgroundColor: tabValue === 0 ? "#FFFFFF" : "transparent",
									boxShadow: "0px 1px 2px 0px #1018280F",
									"&:hover": {
										backgroundColor: tabValue === 0 ? "#transparent" : "#FFFFFF",
									},
									"&.Mui-selected": {
										color: "#344054",
									},
									borderRadius: "6px",
									mr: "8px",
								}}
							/>
							<Tab
								label={
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "16px",
										}}
									>
										Information
										<Badge
											badgeContent={2}
											sx={{
												"& .MuiBadge-badge": {
													backgroundColor: "#F4F4F4",
													color: "#344054",
													fontSize: "12px",
													fontWeight: "bold",
													borderRadius: "50%",
													height: "20px",
													minWidth: "20px",
												},
											}}
										/>
									</Box>
								}
								sx={{
									textTransform: "none",
									fontWeight: "bold",
									backgroundColor: tabValue === 1 ? "#FFFFFF" : "transparent",
									boxShadow: "0px 1px 2px 0px #1018280F",
									"&:hover": {
										backgroundColor: tabValue === 1 ? "#transparent" : "#FFFFFF",
									},
									"&.Mui-selected": {
										color: "#344054",
									},
									borderRadius: "6px",
								}}
							/>
						</Tabs>
						{tabValue === 0 && <Chat messages={chatMessages} sendMessage={sendChatMessage} />}
						{tabValue === 1 && <InformationTab />}
					</div>
				</div>
			</div>
			{isShowAddTeamModal && (
				<AddTeamModal courseId="2" isOpen={isShowAddTeamModal} setIsOpen={setIsShowAddTeamModal} />
			)}
		</>
	);
}
