import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { CompanySchema } from "../../Company/schema/company";
import { DecisionConfirmationTypes } from "../../Course/enums/DecisionConfirmationTypes.enum";
import { FileTypes } from "../enums/FileTypes.enum";

export const CourseFileSchema = z.object({
	id: z.number(),
	fullFilePath: z.string(),
	fileName: z.string(),
	fileType: z.nativeEnum(FileTypes),
	fileLength: z.number(),
});

export const CoursesSchema = z.object({
	id: z.number(),
	name: z.string(),
	json: z
		.string()
		.transform((arg) => JSON.parse(arg))
		.optional(),
	company: CompanySchema,
	courseFiles: CourseFileSchema.array(),
});

export const CreateCoursesSchema = z.object({
	name: z.string(),
	json: z.any().optional(),
});

export const JsonSchema = z.object({
	scenarioName: z.string(),
	author: z.string(),
	Content: z.array(
		z
			.object({
				id: z.number().nullable(),
				phaseName: z.string(),
				timeLimit: z.number().nullable(),
				confirmationRequired: z
					.enum(Object.values(DecisionConfirmationTypes) as [string, ...string[]])
					.optional(),
				timeLeftDecisionId: z.number().min(0).optional(),
				content: z.string(),
				contentType: z.enum(["TEXT", "URL"]),
				decisionName: z.string().optional(),
				decisionOptions: z
					.array(
						z.object({
							option: z.string(),
							phaseId: z.number(),
						})
					)
					.min(1, { message: "The array must have at least one element" })
					.optional(),
			})
			.refine(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(data: any) => {
					const fields = [data?.decisionName, data?.decisionOptions, data?.confirmationRequired];
					const definedFields = fields.filter((field) => field !== undefined);

					return definedFields.length === 0 || definedFields.length === fields.length;
				},
				{
					message:
						"If one of 'decisionName', 'decisionOptions', or 'confirmationRequired' is provided, all three are required.",
					path: [],
				}
			)
	),
});

export const UpdateCoursesSchema = z.object({
	id: z.number().optional(),
	name: z.string().min(1, "Name is required").optional(),
	json: JsonSchema.optional(),
});

export const CoursesResponseSchema = z.object({
	data: z.array(CoursesSchema),
	meta: MetaDataSchema,
});

export type Courses = z.infer<typeof CoursesSchema>;

export type CourseFile = z.infer<typeof CourseFileSchema>;

export type CreateCourses = z.infer<typeof CreateCoursesSchema>;

export type UpdateCourses = z.infer<typeof UpdateCoursesSchema>;

export type CoursesResponse = z.infer<typeof CoursesResponseSchema>;
