import { IConnectedUser } from "../interfaces/IConnectedUser.interface";
import UserIcon, { UserIconSize } from "./UserIcon";

interface IListConnectedUsersProps {
	connectedUsers: IConnectedUser[];
}

export default function ListConnectedUsers({ connectedUsers }: IListConnectedUsersProps) {
	return (
		<div className="flex">
			{connectedUsers?.map((user) => {
				return (
					<UserIcon
						key={user.id}
						firstName={user.firstName}
						isOnline={true}
						lastName={user.lastName}
						size={UserIconSize.Small}
					/>
				);
			})}
		</div>
	);
}
