import { Box } from "@mui/system";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import FormTextField from "@/pages/Private/pages/Account/components/FormTextField";

import AppLogo from "@/assets/images/favicon.png";

import AuthBackground from "@assets/icons/auth-background.svg";

import { ButtonSize } from "@/components/Button/types";

import { FirstLoginSchema, FirstLoginType } from "./schema/firstLogin";

export default function FirstLogin() {
	const { t } = useTranslation();
	const ts = (key: string) => t(`login.${key}`);
	const initialValues: FirstLoginType = {
		firstName: "",
		lastName: "",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FirstLoginType>({
		defaultValues: initialValues,
		resolver: zodResolver(FirstLoginSchema),
	});

	const onSubmit = async (values: FirstLoginType) => {
		try {
			console.log("First login submitted values", values);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="w-full h-full flex flex-col relative">
			<div className="flex flex-col flex-grow items-center gap-8 mt-24">
				<div className="flex flex-col items-center justify-center gap-3">
					<img alt="logo" className="w-[48px] h-[48px] mb-3" src={AppLogo} />
					<h2 className="text-[#101828] text-[30px] leading-[38px] font-semibold text-center">
						{ts("first-login.title")}
					</h2>
					<p className="text-[#667085] text-[16px] leading-[24px] text-center">
						{ts("first-login.description")}
					</p>
					<Box
						alt="Background image"
						className="backdrop-blur-lg"
						component="img"
						src={AuthBackground}
						sx={{
							width: "100%",
							height: "50%",
							position: "absolute",
							zIndex: "-1",
							inset: 0,
						}}
					/>
				</div>
				<Box className="px-4" component="form" onSubmit={handleSubmit(onSubmit)}>
					<FormTextField
						errorMessage={errors?.firstName?.message}
						{...register("firstName")}
						label={t("basics.firstName")}
					/>
					<FormTextField
						errorMessage={errors?.lastName?.message}
						{...register("lastName")}
						label={t("basics.lastName")}
					/>

					<Button
						className="h-[44px]"
						isLoading={false}
						size={ButtonSize.ML}
						title={ts("first-login.continue")}
					/>
				</Box>

				{/* {getAllErrors(error, formErrors).length ? (
					<AuthError>{renderErrorMessages(getAllErrors(error, formErrors))}</AuthError>
				) : null} */}
			</div>
		</div>
	);
}
