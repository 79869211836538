import { faPlus, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { InputAdornment, Stack, TextField } from "@mui/material";

import { Icon, Modal } from "@/components";
import { ButtonColor } from "@/components/Button/types";
import { SvgIcon } from "@/components/Icon/SvgIcon";
import { ReactComponent as Mail } from "@assets/icons/mail.svg";

interface IAddTeamModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	courseId: string;
}

interface ICourseInvite {
	email: string;
}

export default function AddTeamModal({ isOpen, setIsOpen, courseId }: IAddTeamModalProps) {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);

	const [invitees, setInvitees] = useState<ICourseInvite[]>([]);

	useEffect(() => {
		setInvitees([{ email: "" }, { email: "" }, { email: "" }]);
	}, []);

	const handleAddInvitee = () => {
		setInvitees([...invitees, { email: "" }]);
	};

	const handleSendInvite = async () => {
		const filledInvitees = invitees.filter((invitee) => invitee.email.trim());

		console.log("Filled invitees:", filledInvitees);

		if (filledInvitees.length === 0) {
			console.log("Please fill in at least one invite before sending.");

			return;
		}

		const requestBody = {
			users: filledInvitees,
			courseId: courseId,
		};

		try {
			// await invite(requestBody).unwrap();
			console.log("Invitations sent successfully!", requestBody);
			setInvitees([{ email: "" }]);
		} catch (e) {
			console.error("Failed to send invitations:", e);
		}
	};

	const onEmailChange = (index: number, email: string) => {
		const updatedInvitees = [...invitees];

		if (updatedInvitees[index]) {
			updatedInvitees[index] = { email };
		}

		setInvitees(updatedInvitees);
	};

	return (
		<Modal
			aboveHeader={
				<div
					className="flex mt-6 ml-6 justify-center items-center rounded-xl border border-blue-100 text-gray-700 cursor-pointer pointer-events-auto"
					style={{ width: "48px", height: "48px" }}
					onClick={() => console.log("Add user click")}
				>
					<Icon className="w-[24px] h-[24px]" icon={faUserPlus} />
				</div>
			}
			handleClose={() => setIsOpen(false)}
			handleSave={handleSendInvite}
			isLoading={false}
			isOpened={isOpen}
			submitButtonColor={ButtonColor.ACTION}
			submitButtonText={t("basics.confirm")}
			title={""}
		>
			<Stack className="" spacing={3}>
				<div className="flex-grow w-full">
					<h3 className="mb-1">{ts("start.invite-colleagues-title")}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">
						{ts("start.invite-colleagues-description")}
					</p>
				</div>
				<div className="flex-grow">
					<div className="flex flex-col">
						{invitees.map((invitee, index) => (
							<TextField
								key={index}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position="start"
											sx={{
												"&:not(.MuiInputAdornment-hiddenLabel)": {
													marginTop: "0px !important",
												},
											}}
										>
											<div className="mr-2 flex items-center">
												<SvgIcon className="w-[20px] h-[20px] text-gray-500" svgIcon={Mail} />
											</div>{" "}
										</InputAdornment>
									),
								}}
								className="w-full"
								inputProps={{
									style: {
										display: "flex",
										alignItems: "center",
										padding: "10px 0px",
									},
								}}
								placeholder="you@example.com"
								value={invitee.email}
								onChange={(e) => onEmailChange(index, e?.target?.value)}
							/>
						))}
						<div className="flex flex-row justify-between">
							<div
								className="w-auto text-[14px] font-[600] cursor-pointer"
								onClick={handleAddInvitee}
							>
								<Icon icon={faPlus} /> Add another
							</div>
						</div>
					</div>
				</div>
			</Stack>
		</Modal>
	);
}
