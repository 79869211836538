import { FormLabel, TextField } from "@mui/material";
import { HTMLInputTypeAttribute } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IFormTextField {
	label: string;
	errorMessage?: string;
	startAdornment?: JSX.Element;
	containerClassName?: string;
	formRegister?: UseFormRegisterReturn;
	autoComplete?: string;
	type?: HTMLInputTypeAttribute;
	placeHolder?: string;
}

export default function FormTextField({
	label,
	errorMessage,
	startAdornment,
	containerClassName,
	formRegister,
	autoComplete,
	type,
	placeHolder,
}: IFormTextField) {
	const { t } = useTranslation();

	return (
		<div className={`space-y-1 ${containerClassName}`}>
			<FormLabel component="label" sx={{ color: "#344054" }}>
				{label}
			</FormLabel>
			<TextField
				fullWidth
				FormHelperTextProps={{
					sx: {
						fontSize: "0.825rem",
						color: "#FF0000",
					},
				}}
				InputProps={{
					sx: {
						fontSize: "1rem",
						color: "#101828",
						fontWeight: "400",
						lineHeight: "1.5rem",
						"&:focus-visible": {
							outline: "none",
							inset: "none",
							border: "none",
						},
						paddingY: "7px",
						"& input": {
							paddingY: "7px",
						},
						"&:focus": {
							outline: "none",
							boxShadow: "none",
							inset: "none",
							border: "none",
						},
						backgroundColor: "#fff",
					},
					startAdornment,
				}}
				autoComplete={autoComplete}
				className="autofill:bg-white autofill:p-0"
				error={!!errorMessage}
				helperText={errorMessage}
				placeholder={placeHolder ?? t("basics.enter-your") + label}
				sx={{
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							border: "1px solid #D0D5DD",
						},
						"&:hover fieldset": {
							border: "1px solid #3E478470",
						},
						"&.Mui-focused fieldset": {
							border: "none",
							outline: "none",
							inset: "none",
						},
						mb: !!errorMessage ? "-2px" : "20px",
					},
				}}
				type={type}
				variant="outlined"
				{...(formRegister ?? {})}
			/>
		</div>
	);
}
