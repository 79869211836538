import { FunctionComponent, ReactElement } from "react";

import { ErrorPageType, PermissionRoles } from "@/enum";
import { useAppSelector } from "@/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { getAllUserPermissions } from "@/utils";

import { ErrorPage } from "../ErrorPage/ErrorPage";

interface PermissionCheckProps {
	children: ReactElement;
	requiredPermissions: PermissionRoles[];
	redirect?: boolean;
}

export const PermissionCheck: FunctionComponent<PermissionCheckProps> = ({
	children,
	requiredPermissions,
	redirect,
}) => {
	const { user } = useAppSelector(authSelector);

	const userPermissions = getAllUserPermissions(user?.role);
	const hasPermission = requiredPermissions.every((permission) =>
		userPermissions.includes(permission)
	);

	return hasPermission ? children : redirect ? <ErrorPage type={ErrorPageType.Forbidden} /> : null;
};
