export enum ConfirmationRequirement {
	NONE = "NONE",
	FROM_ANYONE = "FROM_ANYONE",
	FROM_ALL = "FROM_ALL",
}

export enum ContentType {
	TEXT = "TEXT",
	URL = "URL",
}

export type CourseScenario = {
	scenarioName: string;
	author: string;
	Content: Array<{
		id: number | null;
		phaseName: string;
		timeLimit: number | null;
		confirmationRequired: ConfirmationRequirement;
		timeLeftDecisionId: number;
		decisionName: string;
		content: string;
		contentType: ContentType;
		decisionOptions: Array<{
			option: string;
			phaseId: number;
		}>;
	}>;
};

export const EMPTY_COURSE_SCENARIO: CourseScenario = {
	scenarioName: "",
	author: "",
	Content: [
		{
			id: null,
			phaseName: "",
			timeLimit: null,
			confirmationRequired: ConfirmationRequirement.NONE,
			decisionName: "",
			content: "",
			contentType: ContentType.TEXT,
			decisionOptions: [],
			timeLeftDecisionId: -1,
		},
	],
};
