import { faFlag } from "@fortawesome/pro-regular-svg-icons";

import { useTranslation } from "react-i18next";

import { Icon } from "@/components";
import { ButtonColor, ButtonSize, FontSize } from "@/components/Button/types";
import { formatTimestamp } from "@/utils/formatTimestamp";
import { Button } from "@/components/Button/Button";
import { useAppSelector } from "@/redux/hooks";

import { ScenarioActionTypes } from "../../../enums/ScenarioActionTypes.enum";

interface IEndScenarioMessageProps {
	timestamp: number;
	sendScenarioAction: (type: ScenarioActionTypes, data: unknown) => void;
	responsibleForEmergencyId: number;
}

export default function EndScenarioMessage({
	timestamp,
	sendScenarioAction,
	responsibleForEmergencyId,
}: IEndScenarioMessageProps) {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);
	const currentUserId = useAppSelector((state) => state.auth.user?.id);

	const onClickFinishScenario = () => {
		if (currentUserId === responsibleForEmergencyId) {
			sendScenarioAction(ScenarioActionTypes.FINISH_SCENARIO, {});
		}
	};

	return (
		<div className="my-6">
			<div className="flex justify-between px-1">
				<p className="text-[0.875rem] text-[#344054] font-medium">{"End session"}</p>
				<p className="text-[0.75rem] text-[#475467]">{formatTimestamp(timestamp)}</p>
			</div>
			<Button
				color={ButtonColor.PRIMARY}
				disabled={currentUserId !== responsibleForEmergencyId}
				fontSize={FontSize.M}
				image={
					<div className="mr-2 flex items-center justify-center">
						<Icon className="w-4 h-4" icon={faFlag} />
					</div>
				}
				size={ButtonSize.L}
				testId="new-user-button"
				title={ts("progress.complete")}
				onClick={onClickFinishScenario}
			/>
		</div>
	);
}
