import { useCallback, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

import { useAppSelector } from "@/redux/hooks";

import { ScenarioActionTypes } from "../pages/Course/enums/ScenarioActionTypes.enum";
import { ScenarioMessageTypes } from "../pages/Course/enums/ScenarioMessageTypes.enum";
import { IConnectedUser } from "../pages/Course/interfaces/IConnectedUser.interface";
import { ScenarioMessageInfo } from "../pages/Course/interfaces/ScenarioMessageInfo.interface";
import { ScenarioProgress } from "../pages/Course/interfaces/ScenarioProgress.interface";

export enum SocketEvents {
	ACTION = "action",
	ACTUAL_SCENARIO_DATA = "actual_scenario_data",
	SCENARIO_ACTION = "scenario_action",
	GET_ACTUAL_SCENARIO_DATA = "get_actual_scenario_data",
	PROTOCOL = "protocol",
	CHAT = "chat",
	SEND_CHAT = "send_chat",
	UPDATE_PROTOCOL = "update_protocol",
	SEND_PROTOCOL = "send_protocol",
	UPDATE_CONNECTED_USERS = "update_connected_users",
	JOIN_ROOM = "join_room",
	ERROR = "error",
}

const useSocketConnection = (
	courseId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onActionHandler: (data: any) => void
) => {
	const token = useAppSelector((state) => state.auth?.token);
	const [socket, setSocket] = useState<Socket | null>(null);
	const [currentStageEndTimestamp, setCurrentStageEndTimestamp] = useState<number>(0);
	const [connectedUsers, setConnectedUsers] = useState<IConnectedUser[]>([]);
	const [currentStage, setCurrentStage] = useState<number>(0);
	const [chatMessages, setChatMessages] = useState<ScenarioMessageInfo[]>([]);
	const [protocolMessages, setProtocolMessages] = useState<ScenarioMessageInfo[]>([]);

	useEffect(() => {
		if (!token || !courseId) {
			return;
		}

		const newSocket = io(process.env.REACT_APP_API_URL, {
			query: { token },
		});

		newSocket.on("connect_error", (error) => {
			console.error("Socket connection error:", error);
		});

		newSocket.on("disconnect", (reason) => {
			console.error("Socket disconnected:", reason);
		});

		setSocket(newSocket);

		newSocket.emit(SocketEvents.JOIN_ROOM, courseId);

		newSocket.on(SocketEvents.CHAT, (data) => {
			setChatMessages((prevMessages) => {
				const messageExists = prevMessages.some((item) => item.id === data.id);

				if (messageExists) {
					return prevMessages.map((item) => (item.id === data.id ? data : item));
				} else {
					return [...prevMessages, data];
				}
			});
		});

		newSocket.on(SocketEvents.PROTOCOL, (data) => {
			setProtocolMessages((prevMessages) => {
				const messageExists = prevMessages.some((item) => item.id === data.id);

				if (messageExists) {
					return prevMessages.map((item) => (item.id === data.id ? data : item));
				} else {
					return [...prevMessages, data];
				}
			});
		});

		newSocket.on(SocketEvents.ACTUAL_SCENARIO_DATA, (data: ScenarioProgress) => {
			setProtocolMessages(data.protocolHistory);
			setCurrentStage(data.currentStage);
			setChatMessages(data.chatHistory);
			if (data.currentStageEndTimestamp) {
				setCurrentStageEndTimestamp(data.currentStageEndTimestamp);
			} else {
				setCurrentStageEndTimestamp(0);
			}
		});

		newSocket.on(SocketEvents.UPDATE_CONNECTED_USERS, (data) => {
			setConnectedUsers(data);
		});

		newSocket.on(SocketEvents.ACTION, (data) => {
			onActionHandler(data);
		});

		return () => {
			newSocket.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, courseId]);

	const sendChatMessage = useCallback(
		(message: string) => {
			if (socket) {
				socket.emit(SocketEvents.SEND_CHAT, { message, courseId });
			}
		},
		[socket, courseId]
	);

	const sendProtocolMessage = useCallback(
		(type: ScenarioMessageTypes, data: unknown) => {
			if (socket) {
				socket.emit(SocketEvents.SEND_PROTOCOL, { type, data, courseId });
			}
		},
		[socket, courseId]
	);

	const updateProtocolMessage = useCallback(
		(type: ScenarioMessageTypes, messageId: string, data: unknown) => {
			if (socket) {
				socket.emit(SocketEvents.UPDATE_PROTOCOL, { type, data, messageId, courseId });
			}
		},
		[socket, courseId]
	);

	const getScenarioActualData = useCallback(() => {
		if (socket) {
			socket.emit(SocketEvents.GET_ACTUAL_SCENARIO_DATA, { courseId });
		}
	}, [socket, courseId]);

	const sendScenarioAction = useCallback(
		(type: ScenarioActionTypes, data: unknown) => {
			if (socket) {
				socket.emit(SocketEvents.SCENARIO_ACTION, { type, data, courseId });
			}
		},
		[socket, courseId]
	);

	return {
		socket,
		currentStage,
		connectedUsers,
		chatMessages,
		protocolMessages,
		currentStageEndTimestamp,
		sendChatMessage,
		sendProtocolMessage,
		updateProtocolMessage,
		sendScenarioAction,
		getScenarioActualData,
	};
};

export default useSocketConnection;
