import { useTranslation } from "react-i18next";

import { faAlarmClock } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "@/components";
import { formatTimestamp } from "@/utils/formatTimestamp";

interface ITimeUpMessage {
	timestamp: number;
	leftTimeInSeconds?: number;
}

export default function TimeUpMessage({ timestamp, leftTimeInSeconds }: ITimeUpMessage) {
	const { t } = useTranslation();
	const ts = (key: string, options = {}) => t(`courses.progress.${key}`, options);

	return (
		<div className="my-2">
			<div className="flex justify-between px-1">
				<p className="text-[0.875rem] text-[#F04438] font-medium">{"End session"}</p>
				<p className="text-[0.75rem] text-[#F04438]">{formatTimestamp(timestamp)}</p>
			</div>
			<div
				className={`bg-[#F0443820] flex-1 text-[#101828] px-[14px] py-[10px] rounded-lg flex justify-start items-center gap-2`}
			>
				<Icon className="text-[#F04438]" icon={faAlarmClock} />
				<p className={`text-[1rem] text-[#F04438]`}>
					{leftTimeInSeconds
						? ts("time-up", { leftTime: leftTimeInSeconds / 60 })
						: ts("time-up-empty")}
				</p>
			</div>
		</div>
	);
}
