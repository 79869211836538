import { IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction, useState } from "react";

import { faCopy, faFileDownload, faLoader, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import { Icon } from "@/components/Icon/Icon";
import { SvgIcon } from "@/components/Icon/SvgIcon";
import { useCourseFiles } from "@/pages/Private/helpers/useCourseFiles";
import { ReactComponent as UploadCloud } from "@assets/icons/upload-cloud.svg";

import { FileTypes } from "../enums/FileTypes.enum";
import FilesIcon from "./FilesIcon";

export interface IFileInfo {
	file?: File;
	id: number;
	fullFilePath: string;
	fileName: string;
	fileLength: number;
	fileType: FileTypes;
}

interface IFilesUploaderProps {
	files: IFileInfo[];
	setFiles: Dispatch<SetStateAction<IFileInfo[]>>;
}

export const MAX_SIZE_IN_BYTES = 20 * 1024 * 1024; // 20MB

export const ALLOWED_FILE_TYPES = [
	"image/jpeg",
	"image/png",
	"image/gif",
	"image/svg+xml",
	"application/pdf",
	"video/mp4",
	"video/avi",
	"video/mkv",
	"audio/wav",
	"audio/mpeg",
];

export default function FilesUploader({ files, setFiles }: IFilesUploaderProps) {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);
	const { isLoading: isFilesLoading, uploadCourseFiles, deleteCourseFile } = useCourseFiles();

	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [isDragging, setIsDragging] = useState(false);

	const handleFileUpload = async (file: File) => {
		setUploadedFile(file);

		try {
			const fileUrls = await uploadCourseFiles([file]);

			const id = fileUrls?.[0]?.id;
			const fullFilePath = fileUrls?.[0]?.fullFilePath;
			const fileName = fileUrls?.[0]?.fileName;
			const fileLength = fileUrls?.[0]?.fileLength;
			const fileType = fileUrls?.[0]?.fileType;

			if (id && fullFilePath && fileName && fileLength && fileType) {
				setFiles((prevFiles) => [
					...prevFiles,
					{ file, fileLength, fileName, fullFilePath, id, fileType },
				]);
			}
		} catch (e) {
			console.log("Error file uploading:", e);
		} finally {
			setUploadedFile(null);
		}
	};

	const handleRemoveFile = async (fileToRemove: IFileInfo) => {
		try {
			if (fileToRemove.fullFilePath) {
				await deleteCourseFile(fileToRemove.fullFilePath);
			}

			setFiles((prevFiles) =>
				prevFiles.filter((fileInfo: IFileInfo) => fileInfo.fileName !== fileToRemove.fileName)
			);
		} catch (e) {
			console.log("Error file uploading:", e);
		}
	};

	const validateFile = (file: File) => {
		if (file.size > MAX_SIZE_IN_BYTES) {
			alert("File size exceeds the 20MB limit.");

			return false;
		}

		if (!ALLOWED_FILE_TYPES.includes(file.type)) {
			alert("This file type is not allowed.");

			return false;
		}

		return true;
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (file && validateFile(file)) {
			handleFileUpload(file);
		}
	};

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(true);
	};

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);

		const file = e.dataTransfer.files?.[0];

		if (file && validateFile(file)) {
			handleFileUpload(file);
		}
	};

	return (
		<Box>
			<Box
				sx={{
					border: "1px solid #EAECF0",
					borderRadius: "12px",
					padding: 4,
					textAlign: "center",
					backgroundColor: isDragging ? "#f0f8ff" : "white",
					cursor: "pointer",
					transition: "background-color 0.3s ease-in-out",
				}}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>
				<label htmlFor="file-upload" style={{ cursor: "pointer" }}>
					<Box
						alignItems="center"
						bgcolor={"white"}
						borderRadius="12px"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						textAlign="center"
					>
						{!isDragging ? (
							<>
								<SvgIcon
									className="h-[40px] w-[40px] p-[10px] border rounded-lg border-gray-200 text-gray-600"
									svgIcon={UploadCloud}
								/>
								<Typography variant="body2">{ts("upload.title")}</Typography>
								<Typography variant="caption">{ts("upload.description")}</Typography>
							</>
						) : (
							<Box p={4}>
								<Typography variant="body2">{ts("upload.drop")}</Typography>
							</Box>
						)}
					</Box>
				</label>
				<input
					accept="video/*,image/*,.pdf"
					id="file-upload"
					style={{ display: "none" }}
					type="file"
					onChange={handleFileChange}
				/>
			</Box>

			<List sx={{ display: "flex", flexDirection: "column", gap: 1, marginTop: 2 }}>
				{files?.map((fileInfo, index) => (
					<ListItem
						key={index}
						sx={{
							backgroundColor: "white",
							borderRadius: "12px",
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<FilesIcon fileType={fileInfo.fileType} />
						<ListItemText
							primary={fileInfo?.fileName}
							secondary={`${((fileInfo?.fileLength ?? 1) / 1024).toFixed(2)} KB`}
						/>
						<IconButton
							disabled={isFilesLoading}
							onClick={() => {
								navigator.clipboard.writeText(fileInfo.fullFilePath);
							}}
						>
							<Icon icon={faCopy} size="xs" />
						</IconButton>
						<IconButton disabled={isFilesLoading} onClick={() => handleRemoveFile(fileInfo)}>
							<Icon icon={faTrashCan} size="xs" />
						</IconButton>
					</ListItem>
				))}
			</List>

			{uploadedFile && (
				<Box
					alignItems="center"
					bgcolor={"white"}
					borderRadius="12px"
					display="flex"
					gap={2}
					mt={2}
					p={4}
				>
					<Icon icon={faFileDownload} size="xl" />
					<Box flexGrow={1} mr={2}>
						<Typography fontSize={"14px"} fontWeight={500}>
							{uploadedFile?.name}
						</Typography>
						<Typography fontSize={"14px"}>{(uploadedFile?.size / 1024).toFixed(2)} KB</Typography>
					</Box>
					{isFilesLoading && <Icon className="animate-spin" icon={faLoader} size="xl" />}
				</Box>
			)}
		</Box>
	);
}
