import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { CourseDetails } from "../Course/components/CourseDetails";
import { CoursesOverview } from "./pages/CoursesOverview/CoursesOverview";
import CourseInProgress from "../Course/components/CourseInProgress";
import { CreateCoursesForm } from "./components/CreateCoursesForm";
import { UpdateCoursesForm } from "./components/UpdateCoursesForm";
import EndSession from "../Course/components/EndSession";

export const Courses: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<CoursesOverview />} path="/" />
			<Route element={<CourseDetails />} path="/:id" />
			<Route element={<CourseInProgress />} path="/:id/progress" />
			<Route element={<CreateCoursesForm />} path="/create" />
			<Route element={<UpdateCoursesForm />} path="/edit/:id" />
			<Route element={<EndSession />} path="/:id/end" />
		</Routes>
	);
};
