import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AuthError } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import AppLogo from "@/assets/images/favicon.png";

import { Button } from "@/components/Button/Button";

import { ButtonSize } from "@/components/Button/types";

import FormTextField from "@/pages/Private/pages/Account/components/FormTextField";

import AuthBackground from "@assets/icons/auth-background.svg";

import { useLoginMutation } from "../../redux/auth.api";
import { LoginSchema, LoginType } from "./schema/login";

export const Login: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`login.${key}`);

	const [login, { isLoading, error }] = useLoginMutation();

	const initialValues: LoginType = {
		email: "",
		password: "",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginType>({
		defaultValues: initialValues,
		resolver: zodResolver(LoginSchema),
	});

	const formErrors = Object.values(errors).map((error) => error.message) as ERROR_TYPE[];

	const onSubmit = async (values: LoginType) => {
		try {
			await login(values).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="w-full h-full flex">
			<div className="w-full h-full flex flex-col relative lg:w-1/2">
				<div className="flex flex-col flex-grow justify-center items-center gap-8">
					<div className="flex flex-col items-center justify-center gap-3">
						<img alt="logo" className="w-[48px] h-[48px] mb-3" src={AppLogo} />
						<h2 className="text-[#101828] text-[30px] leading-[38px] font-semibold text-center">
							{ts("title")}
						</h2>
						<p className="text-[#667085] text-[16px] leading-[24px] text-center">
							{ts("description")}
						</p>
						<Box
							alt="Background image"
							className="backdrop-blur-lg"
							component="img"
							src={AuthBackground}
							sx={{
								width: "100%",
								height: "50%",
								position: "absolute",
								zIndex: "-1",
								inset: 0,
							}}
						/>
					</div>
					<Box
						autoComplete="on"
						className="px-4"
						component="form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<FormTextField
							autoComplete="email"
							errorMessage={errors?.email?.message}
							formRegister={register("email")}
							label={t("basics.email")}
							type="email"
						/>
						<FormTextField
							autoComplete="password"
							errorMessage={errors?.password?.message}
							formRegister={register("password")}
							label={t("basics.password")}
							type="password"
						/>

						<Button
							className="h-[44px] mt-2"
							isLoading={isLoading}
							size={ButtonSize.ML}
							title={ts("loginCta")}
							type="submit"
						/>
					</Box>

					{getAllErrors(error, formErrors).length ? (
						<AuthError>{renderErrorMessages(getAllErrors(error, formErrors))}</AuthError>
					) : null}
				</div>
				<footer className="p-8 text-[#475467] text-[14px]">© Company 2024</footer>
			</div>
			<div className="hidden h-full bg-[#3E4784] lg:block lg:w-1/2" />
		</div>
	);
};
