/* eslint-disable @typescript-eslint/no-explicit-any */
import { RadioGroup } from "@mui/material";

import { faCheck, faRocket } from "@fortawesome/pro-regular-svg-icons";

import { useTranslation } from "react-i18next";

import { Icon } from "@/components";
import { Button } from "@/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { useAppSelector } from "@/redux/hooks";
import { formatTimestamp } from "@/utils/formatTimestamp";

import { ScenarioActionTypes } from "../../../enums/ScenarioActionTypes.enum";
import { ScenarioMessageTypes } from "../../../enums/ScenarioMessageTypes.enum";
import { IDecisionInfo, IDecisionOption } from "../../../interfaces/IDecisionInfo.interface";
import { DecisionConfirmationTypes } from "../../../enums/DecisionConfirmationTypes.enum";

interface IChatDecision {
	messageId: string;
	decisionInfo: IDecisionInfo;
	timestamp: number;
	onActionClick: (type: ScenarioMessageTypes, messageId: string, data: unknown) => void;
	responsibleForEmergencyId: number;
	sendScenarioAction: (type: ScenarioActionTypes, data: unknown) => void;
	currentPhaseId: number;
	isLastPhase: boolean;
}

export default function ChatDecision({
	decisionInfo,
	onActionClick,
	messageId,
	timestamp,
	responsibleForEmergencyId,
	sendScenarioAction,
	currentPhaseId,
	isLastPhase,
}: IChatDecision) {
	const { t } = useTranslation();
	const ts = (key: string) => t(`courses.${key}`);
	const currentUserId = useAppSelector((state) => state.auth?.user?.id ?? -1);
	const isResponsibleForEmergency = responsibleForEmergencyId === currentUserId;
	let isShowNextPhase = false;

	const handleOptionChange = (selectedOption: IDecisionOption) => {
		if (
			(decisionInfo.confirmationRequired === DecisionConfirmationTypes.NONE &&
				!isResponsibleForEmergency) ||
			decisionInfo?.finalDecision
		) {
			return;
		}

		if (selectedOption.userVotedIds.some((userId) => userId === currentUserId)) {
			return;
		}

		const updatedOptions = decisionInfo.decisionOptions.map((option) => {
			if (option.option === selectedOption.option) {
				return {
					...option,
					userVotedIds: [...option?.userVotedIds, currentUserId],
				};
			}

			return {
				...option,
				userVotedIds: option?.userVotedIds?.filter((userId) => userId !== currentUserId),
			};
		});

		onActionClick(ScenarioMessageTypes.DECISION, messageId, {
			...decisionInfo,
			decisionOptions: updatedOptions,
		});
	};

	// if err vote for something
	if (
		decisionInfo?.confirmationRequired === DecisionConfirmationTypes.NONE &&
		decisionInfo.decisionOptions?.some(
			(item) => item.userVotedIds.includes(currentUserId) && item.phaseId !== currentPhaseId
		) &&
		isResponsibleForEmergency
	) {
		isShowNextPhase = true;
		// if someone vote for something
	} else if (decisionInfo?.confirmationRequired === DecisionConfirmationTypes.FROM_ANYONE) {
		const maxVotes = Math.max(
			...decisionInfo.decisionOptions.map((item) => item.userVotedIds.length)
		);
		const optionsWithMaxVotes = decisionInfo.decisionOptions.filter(
			(item) => item.userVotedIds.length === maxVotes
		);

		isShowNextPhase =
			maxVotes > 0 &&
			optionsWithMaxVotes.length === 1 &&
			optionsWithMaxVotes.some((item) => item.phaseId !== currentPhaseId);
	}

	const onNextPhaseClick = () => {
		let finalDecision: IDecisionOption | null = null;

		if (decisionInfo?.confirmationRequired === DecisionConfirmationTypes.NONE) {
			const votedByErr = decisionInfo.decisionOptions?.find((item) =>
				item.userVotedIds.includes(currentUserId)
			);

			if (votedByErr) {
				finalDecision = votedByErr;
			}
		} else if (decisionInfo?.confirmationRequired === DecisionConfirmationTypes.FROM_ANYONE) {
			const maxVotes = Math.max(
				...decisionInfo.decisionOptions.map((item) => item.userVotedIds.length)
			);
			const optionsWithMaxVotes = decisionInfo.decisionOptions.filter(
				(item) => item.userVotedIds.length === maxVotes
			);

			if (maxVotes > 0 && optionsWithMaxVotes.length === 1) {
				finalDecision = optionsWithMaxVotes[0];
			}
		}

		if (finalDecision) {
			onActionClick(ScenarioMessageTypes.DECISION, messageId, {
				...decisionInfo,
				finalDecision,
			});
			if (!isLastPhase) {
				sendScenarioAction(ScenarioActionTypes.NEW_STAGE, {
					nextStageId: finalDecision.phaseId,
				});
			}
		}
	};

	return (
		<div className="mt-2">
			<div className="flex justify-between px-1">
				<p className="text-[0.875rem] text-[#344054] font-medium">{decisionInfo.name}</p>
				<p className="text-[0.75rem] text-[#475467]">{formatTimestamp(timestamp)}</p>
			</div>
			<RadioGroup className="space-y-[6px]">
				{decisionInfo?.decisionOptions
					.filter((optionData) =>
						decisionInfo?.finalDecision
							? decisionInfo?.finalDecision?.option === optionData.option
							: true
					)
					.map((option, index) => {
						const isSelected = decisionInfo?.finalDecision
							? false
							: option?.userVotedIds?.includes(currentUserId) ||
							  (decisionInfo.confirmationRequired === DecisionConfirmationTypes.NONE &&
									option?.userVotedIds?.includes(responsibleForEmergencyId));

						return (
							<div key={index} className="flex w-full gap-3 items-center">
								<div
									className={`bg-[#F2F4F7] flex-1 text-[#101828] px-[14px] py-[10px] rounded-lg flex justify-between items-center ${
										isSelected && "border-[1px] border-[#4E5BA6]"
									}`}
									onClick={() => handleOptionChange(option)}
								>
									<p className={`text-[1rem] text-[#101828]`}>{option.option}</p>
									{decisionInfo?.finalDecision ? (
										<></>
									) : isSelected ? (
										<p className="w-4 h-4 rounded-full flex items-center justify-center bg-[#3E4784]">
											<Icon className="text-white w-[10px] h-[10px]" icon={faCheck} />
										</p>
									) : (
										<p className="w-4 h-4 rounded-full bg-white border-[1px] border-[#D0D5DD]"></p>
									)}
								</div>
								{decisionInfo?.finalDecision ? (
									<></>
								) : (
									decisionInfo.confirmationRequired === DecisionConfirmationTypes.FROM_ANYONE && (
										<div className="flex items-center justify-center gap-2 px-[10px] border-[1px] h-7 border-[#D0D5DD] rounded-lg">
											<span className="bg-[#4E5BA6] w-2 h-2 rounded-full"></span>
											<p className="text-[#344054] text-[14px] font-semibold">
												{option?.userVotedIds?.length ?? 0}
											</p>
										</div>
									)
								)}
							</div>
						);
					})}
			</RadioGroup>

			{isShowNextPhase && !decisionInfo?.finalDecision && (
				<Button
					className="h-[40px] !w-auto mt-[6px]"
					color={ButtonColor.PRIMARY}
					image={
						<div className="mr-2 flex items-center justify-center">
							<Icon className="w-4 h-4" icon={faRocket} />
						</div>
					}
					size={ButtonSize.ML}
					testId="new-user-button"
					title={isLastPhase ? ts("progress.save") : ts("progress.next")}
					onClick={onNextPhaseClick}
				/>
			)}
		</div>
	);
}
