import { api } from "@/redux/api";

import {
	CourseProgress,
	CourseProgressResponseSchema,
	StartCourseProgress,
} from "../../pages/Course/schema/course-progress";

export const coursesProgressApi = api.injectEndpoints({
	endpoints: (builder) => ({
		startScenario: builder.mutation<Partial<CourseProgress>, StartCourseProgress>({
			query: (body) => {
				return {
					url: `course-progress/start`,
					method: "POST",
					body,
					responseSchema: CourseProgressResponseSchema,
				};
			},
			invalidatesTags: ["CourseProgress"],
		}),
		getScenarioProgress: builder.query<CourseProgress, string>({
			query: (id: string) => ({
				url: `course-progress/${id}`,
				params: {
					id,
				},
				responseSchema: CourseProgressResponseSchema,
			}),
		}),
	}),
});

export const { useGetScenarioProgressQuery, useStartScenarioMutation } = coursesProgressApi;
export const accountApiReducer = coursesProgressApi.reducer;
export const accountApiMiddleware = coursesProgressApi.middleware;
