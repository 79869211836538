import { faCheck, faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Icon } from "@/components";
import { CourseProgressEnum } from "@/enum/course-progress.enum";

export default function EndSession() {
	const { t } = useTranslation();
	const ts = (key: string, options = {}) => t(`courses.end.${key}`, options);
	const location = useLocation();
	const { scenarioName, status, additionalStatusInfo } = location.state || {};

	const iconAccordingToStatus = useMemo(() => {
		switch (status) {
			case CourseProgressEnum.Success:
				return (
					<Icon
						className="w-14 h-14 rounded-full text-green-700 border-2 border-green-700 p-2"
						icon={faCheck}
					/>
				);
			case CourseProgressEnum.Failed:
				return <Icon className="w-16 h-16 rounded-full text-red-500" icon={faCircleExclamation} />;
			default:
				return <Icon className="w-16 h-16 rounded-full text-red-500" icon={faCircleExclamation} />;
		}
	}, [status]);

	return (
		<div className="w-full h-full flex justify-center items-center">
			<div className="w-[60%] py-20 flex flex-col justify-center items-center space-y-5 px-16 border-[1px] border-[#EAECF0] rounded-xl shadow-lg">
				{iconAccordingToStatus}
				{status && scenarioName ? (
					<Typography
						gutterBottom
						className="text-center text-wrap"
						sx={{
							fontWeight: "600",
							fontSize: "30px",
							lineHeight: "36px",
							color: "#101828",
							mb: "0px",
						}}
					>
						{ts(status, { scenarioName })}
					</Typography>
				) : (
					<Typography
						gutterBottom
						className="text-center text-wrap"
						sx={{
							fontWeight: "600",
							fontSize: "30px",
							lineHeight: "36px",
							color: "#101828",
							mb: "0px",
						}}
					>
						{ts("no-info")}
					</Typography>
				)}
				{additionalStatusInfo && (
					<Typography
						gutterBottom
						className="text-center text-wrap"
						sx={{
							fontWeight: "400",
							fontSize: "18px",
							lineHeight: "22px",
							color: "#101828",
							mb: "0px",
						}}
					>
						{ts(additionalStatusInfo)}
					</Typography>
				)}
			</div>
		</div>
	);
}
