import { useCallback } from "react";

import { faFile, faText, faVideo } from "@fortawesome/pro-regular-svg-icons";

import { faImage } from "@fortawesome/pro-light-svg-icons";

import { Icon } from "@/components";

import BlockTitle from "./ChatComponents/BlockTitle";

export enum PhaseInformationType {
	FILE = "FILE",
	VIDEO = "VIDEO",
	TEXT = "TEXT",
	IMAGE = "IMAGE",
}

export interface IPhaseInformationItem {
	timestamp: string;
	type: PhaseInformationType;
	description: string;
}

export default function InformationTab() {
	const information: IPhaseInformationItem[] = [
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.FILE,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.IMAGE,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.TEXT,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.VIDEO,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.FILE,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.IMAGE,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.TEXT,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.VIDEO,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.FILE,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.IMAGE,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.TEXT,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
		{
			timestamp: "5 mins ago",
			type: PhaseInformationType.VIDEO,
			description:
				"Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. ",
		},
	];

	const getIcon = useCallback((type: PhaseInformationType) => {
		switch (type) {
			case PhaseInformationType.FILE:
				return <Icon className="w-5 h-5" icon={faFile} />;
			case PhaseInformationType.IMAGE:
				return <Icon className="w-5 h-5" icon={faImage} />;
			case PhaseInformationType.TEXT:
				return <Icon className="w-5 h-5" icon={faText} />;
			case PhaseInformationType.VIDEO:
				return <Icon className="w-5 h-5" icon={faVideo} />;
		}
	}, []);

	return (
		<div className="flex-1 flex flex-col overflow-auto custom-scrollbar items-center p-6 bg-[#F9FAFB] border-b-[1px] border-[#EAECF0]">
			{information?.length ? (
				<div className="px-2 flex flex-col gap-4 mt-auto">
					{
						<BlockTitle
							component={
								<p className="px-4 py-[0.625rem] font-semibold text-[14px] rounded-lg bg-[#3E4784] text-white">
									Phase 1
								</p>
							}
						/>
					}
					{information.map((info, indx) => {
						return (
							<div
								key={indx}
								className={`flex-1 space-y-[6px] border-b-[1px] border-[#EAECF0] pb-4`}
							>
								<div className="flex justify-between">
									<p className="text-[0.875rem] text-[#344054] font-medium">Information</p>
									<p className="text-[0.75rem] text-[#475467]">{info.timestamp}</p>
								</div>
								<div className={`flex`}>
									<p className="w-9 h-9 flex items-center justify-center">{getIcon(info.type)}</p>
									<p className={`text-[1rem] flex-1`}>{info.description}</p>
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<p className="flex-1 flex items-center justify-centers w-full">
					{<BlockTitle label="No messages yet" />}
				</p>
			)}
		</div>
	);
}
