import { faBuilding, faPeople } from "@fortawesome/pro-regular-svg-icons";

import i18n from "@/i18n";
import { ROUTE_CONFIG } from "@/routes/config";

import { Icon } from "@/components";
import { PermissionRoles } from "@/enum";

import { ReactComponent as Courses } from "@assets/icons/sidebar/courses.svg";
import { SvgIcon } from "@/components/Icon/SvgIcon";
import { useAppSelector } from "@/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";

export interface SidenavItem {
	title: string;
	path: string;
	onClick?: () => void;
	strictPath?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	iconActive?: any;
	type?: string;
	permission?: PermissionRoles;
	companyIds?: number[];
}

interface NavItemInner {
	upper: SidenavItem[];
	middle?: SidenavItem[];
	secondMiddle?: SidenavItem[];
	lower: SidenavItem[];
}

interface NavItemConfig {
	ALL: NavItemInner;
}

export const useNavigationGetter = (): NavItemConfig => {
	const { user } = useAppSelector(authSelector);

	return {
		ALL: {
			upper: [
				{
					title: i18n.t("sidebar.courses"),
					path: ROUTE_CONFIG.COURSES,
					icon: <SvgIcon className="w-[24px] h-[24px]" svgIcon={Courses} />,
					type: "icon",
					permission: PermissionRoles.COURSES,
				},
				{
					title: i18n.t("sidebar.companies"),
					path: ROUTE_CONFIG.COMPANIES,
					icon: <Icon className="w-[24px] h-[24px]" icon={faBuilding} />,
					type: "icon",
					permission: PermissionRoles.GLOBAL_ADMIN,
				},
				{
					title: i18n.t("sidebar.company"),
					path: `${ROUTE_CONFIG.COMPANIES}/${user?.company?.id}`,
					icon: <Icon className="w-[24px] h-[24px]" icon={faPeople} />,
					type: "icon",
					permission: PermissionRoles.UPDATE_USER,
				},
			],
			middle: [],
			lower: [],
		},
	};
};
