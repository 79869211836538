import { useMemo } from "react";

import {
	faFileAudio,
	faFileLines,
	faImage,
	faQuestion,
	faVideo,
} from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "@/components";

import { FileTypes } from "../enums/FileTypes.enum";

interface IFilesIcon {
	fileType: FileTypes;
}

export default function FilesIcon({ fileType }: IFilesIcon) {
	const fileIcon = useMemo(() => {
		switch (fileType) {
			case FileTypes.Text:
				return <Icon className="w-5 h-5 text-[#667085]" icon={faFileLines} />;
			case FileTypes.Audio:
				return <Icon className="w-5 h-5 text-[#667085]" icon={faFileAudio} />;
			case FileTypes.Image:
				return <Icon className="w-5 h-5 text-[#667085]" icon={faImage} />;
			case FileTypes.Video:
				return <Icon className="w-5 h-5 text-[#667085]" icon={faVideo} />;
			case FileTypes.Unknown:
				return <Icon className="w-5 h-5 text-[#667085]" icon={faQuestion} />;
			default:
				return <Icon className="w-5 h-5 text-[#667085]" icon={faQuestion} />;
		}
	}, [fileType]);

	return <div className="w-9 h-9 flex items-center justify-center">{fileIcon}</div>;
}
