import { FunctionComponent } from "react";

import AppLogo from "@/assets/images/favicon.png";

export const Logo: FunctionComponent<{ handleClick: () => void }> = ({ handleClick }) => {
	return (
		<div className="px-[18px] pt-3 block relative top-0 left-0">
			<img
				alt="logo"
				className="m-2 w-[32px] h-[32px] 13i:max-w-[48px] cursor-pointer z-40"
				src={`${AppLogo}`}
				onClick={handleClick}
			/>
		</div>
	);
};
