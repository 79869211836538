import { CourseProgressEnum } from "@/enum/course-progress.enum";

export default {
	translation: {
		companies: {
			title: "Company Management",
			subtitle: "Manage your teams and user permissions.",
			search: "Nach Organisation suchen",
			name: "Name",
			linkedinUrl: "LinkedIn URL",
			users: "Benutzer",
			website: "Website",
			create: {
				button: "Add New Company",
				title: "Add New Company",
			},
			delete: {
				title: "Delete Company",
			},
			update: {
				title: "Update Company",
			},
		},
		company: {
			title: "Company Management",
			subtitle: "Manage your team members and their account permissions here.",
			tabs: {
				general: "Allgemein",
				user: "Benutzer",
				roles: "Rollen",
			},
			companyInformation: "Firmeninformationen",
			autoInvite: "Autoeinladung zur Firmen Linkedin Page",
			apolloApiKey: "Apollo API Schlüssel",
			lemlistApiKey: "Lemlist API Schlüssel",
		},
		courses: {
			title: "Scenarios",
			subtitle: "Manage your scenarios.",
			search: "Search for scenario",
			name: "Name",
			users: "Users",
			json: "JSON Input",
			create: {
				button: "Create New Scenario",
				title: "Create New Scenario",
			},
			delete: {
				title: "Delete Scenario",
			},
			update: {
				title: "Update Scenario",
				text: "Text",
				json: "JSON",
			},
			start: {
				title: "Start Scenario",
				label: "Select scenario to start",
				"please-select": "Please select scenario",
				"start-training-description":
					'Do you really want to start scenario with name "{{trainingName}}"?',
				"invite-colleagues-title": "Invite team",
				"invite-colleagues-description": "Invite colleagues to collaborate on this scenario.",
			},
			upload: {
				title: "Click to upload or drag and drop",
				description: "(up to 20 MB)",
				drop: "Drop file here",
			},
			progress: {
				protocol: "Protocol",
				next: "Next Phase",
				save: "Save decision",
				complete: "Complete the scenario",
				"time-up":
					"The time is running out, be careful, there are only {{leftTime}} minutes left to complete this stage",
				"time-up-empty":
					"The time for completing the stage is running out, pay attention to the timer",
			},
			end: {
				[CourseProgressEnum.Success]:
					'You have successfully completed the scenario: "{{scenarioName}}"',
				[CourseProgressEnum.Failed]: 'Scenario "{{scenarioName}}" has not been completed',
				success: "Congratulations!",
				"time-up": "The time allotted for the stage was exhausted",
				"no-info": "No information on the result of the scenario is available",
			},
		},
		course: {
			title: "Scenario Management",
			subtitle: "Manage your Scenario.",
			tabs: {
				general: "General",
				user: "Users",
				roles: "User roles",
			},
			courseInformation: "Scenario Information",
		},
		dashboard: {
			title: "Willkommen",
		},
		sidebar: {
			users: "Benutzer",
			roles: "Benutzerrollen",
			logout: "Abmelden",
			accounts: "Konten",
			settings: "Einstellungen",
			accountSettings: "Kontoeinstellungen",
			profile: "Mein Profil",
			companies: "Company Management",
			back: "Zurück zu deinem Account",
			all: "All",
			listViews: "List views",
			campaigns: "Kampagnen",
			courses: "Scenarios",
			cybersecurityIncidentBasics: "Cybersecurity Incident Basics",
		},
		errors: {
			invalidEmail: "Ungültige E-Mail-Adresse",
			invalidPassword: "Ungültiges Passwort",
			passwordGuidelinesNotMet: "Passwortrichtlinien nicht erfüllt",
			termsAcceptanceMissing: "Bitte akzeptieren Sie die Nutzungsbedingungen",
			invalidValue: "Ungültiger Wert",
			invalidData: "Ungültige Daten",
			userNotConfirmed: "Benutzer nicht bestätigt",
			passwordMismatch: "Passwörter stimmen nicht überein",
			invalidToken: "Ungültiges Token",
			emailAlreadyTaken: "E-Mail-Adresse bereits vergeben",
			wentWrong: "Etwas ist schief gelaufen",
			unauthorized: "Nicht autorisiert",
			invalidAuthCode: "Ungültiger Authentifizierungscode",
			nameMissing: "Name fehlt",
			requiredFields: "Nicht alle erforderlichen Felder sind ausgefüllt.",
			expectedNumber: "Nummer erwartet / geben Sie 0 ein, wenn nicht benötigt.",
			customColumnAlreadyExists: "Spalte existiert bereits",
			wrongUrlShouldBeLinkedinProfile: "Falsche URL. Sollte eine LinkedIn-Profil-URL sein",
			notEnoughCredits: "Nicht genügend Credits",
		},
		account: {
			connectionScraping: "LinkedIn-Verbindungen",
			enableConnectionScraping: "Scraping deiner LinkedIn-Verbindungen aktivieren",
			enableConnectionScrapingDescription:
				"Dies ermöglicht zu ermitteln zu welchen Prospects du oder deine Kollegen bereits eine Verindung auf Linkedin haben.",
			linkedinAutomation: "LinkedIn-Automatisierung",
			enableAutoAccept: "Auto-Akzeptieren aktivieren",
			enableAutoAcceptDescription: "Automatisches Akzeptieren von LinkedIn-Verbindungsanfragen",
			profileVisits: "Profilbesuche",
			warningPremiumAccountRequired: "Premium-Account erforderlich",
			enableProfileVisitsScraping: "Profilbesuche aktivieren",
			changePassword: "Passwort ändern",
			currentPassword: "Aktuelles Passwort",
			disableTwoFactorAuth: "2FA deaktivieren",
			enableTwoFactorAuth: "2FA aktivieren",
			enableTwoFactorAuthDescription: "Scannen Sie den QR-Code mit Ihrer Authentifizierungs-App",
			newPassword: "Neues Passwort",
			profileInformation: "Profilinformationen",
			profileSecurity: "Profilsicherheit",
			repeatPassword: "Neues Passwort wiederholen",
			tabs: {
				general: "Allgemein",
				notifications: "Benachrichtigungen",
				linkedinConnections: "LinkedIn-Verbindungen",
			},
			title: "Persönliche Informationen",
			description: "Aktualisieren Sie hier Ihre persönlichen Daten und Einstellungen.",
			save: "Änderungen speichern",
			saving: "Speichern...",
			cancel: "Abbrechen",
			linkedinConnections: "LinkedIn-Verbindungen",
		},
		basics: {
			selectColumns: "Spalten auswählen",
			wait: "Bitte warten",
			reset: "Zurücksetzen",
			pleaseSelect: "Bitte wählen",
			noResults: "Keine Ergebnisse",
			account: "Konto",
			actions: "Aktionen",
			admin: "Admin",
			back: "Zurück",
			block: "Blockieren",
			cancel: "Abbrechen",
			change: "Bearbeiten",
			code: "Code",
			confirmationClose: "Are you sure you want to close the window?",
			confirm: "Bestätigen",
			confirmPassword: "Passwort bestätigen",
			createSomething: "Erstelle {{something}}",
			delete: "Löschen",
			description: "Beschreibung",
			dragAndDropImage: "Ziehen & Ablegen",
			edit: "Bearbeiten",
			editSomething: "Bearbeite {{something}}",
			email: "E-Mail-Adresse",
			enterSomething: "Geben Sie {{something}} ein",
			firstName: "Vorname",
			home: "Startseite",
			label: "Label",
			lastName: "Nachname",
			linkedinUrl: "LinkedIn URL",
			logout: "Abmelden",
			name: "Name",
			noData: "Keine Daten",
			password: "Passwort",
			permissions: "Berechtigungen",
			resetPassword: "Passwort zurücksetzen",
			role: "Rolle",
			save: "Speichern",
			saveChanges: "Änderungen speichern",
			search: "Suche {{something}}...",
			select: "Auswählen",
			selectSomething: "Wähle {{something}}",
			setPassword: "Passwort festlegen",
			status: "Status",
			termsAndConditions:
				"<noLink>Ich habe die</noLink> <link1>Allgemeinen Geschäftsbedingungen</link1> gelesen",
			upload: "Hochladen",
			value: "Wert",
			warning: "Achtung",
			filter: "Filter",
			clear: "Reset All Filters",
			close: "Schließen",
			rows: "Rows",
			columns: "Columns",
			columnsShown: "Columns shown",
			export: "Export",
			next: "Next",
			previous: "Previous",
			addCriteria: "Add Criteria",
			copyCriteria: "Copy Criteria",
			archive: "Archive",
			unarchive: "Unarchive",
			"enter-your": "Enter your ",
		},
		errorPages: {
			buttonText: "Zur Startseite",
			description:
				"Entweder haben Sie einen verdächtigen Weg probiert oder Sie sind versehentlich hierher gekommen. Egal was es ist, versuchen Sie es über die Navigation.",
			notFound: "404: Die Seite, die Sie suchen, ist hier nicht",
			serverError: "500: Interner Serverfehler",
			unauthorize: "401: Autorisierung erforderlich",
			forbidden: "403: Verboten",
		},
		forgotPassword: {
			forgotPasswordCta: "Link zum Zurücksetzen senden",
			submittedFeedbackMessage:
				"Überprüfen Sie Ihren Posteingang. Wir senden eine E-Mail mit Anweisungen, wie Sie Ihr Passwort zurücksetzen können!",
			title: "Passwort vergessen",
		},
		inviteUser: {
			button: "Neuen Nutzer einladen",
			modal: {
				cta: "Einladen",
				emailPlaceholder: "E-Mail eingeben",
				firstNamePlaceholder: "Vorname eingeben",
				lastNamePlaceholder: "Nachname eingeben",
				rolePlaceholder: "Rolle auswählen",
				title: "Benutzer einladen",
			},
		},
		login: {
			emailPlaceholder: "Ihre E-Mail-Adresse",
			forgotPasswordCta: "Passwort vergessen?",
			loginCta: "Anmelden",
			passwordPlaceholder: "Ihr Passwort",
			invalidData: "E-Mail oder Passwort falsch",
			title: "Welcome back",
			description: "Welcome back! Please enter your details.",
			"first-login": {
				title: "Set your Name",
				description: "This is your name that will be displayed in your scenarios.",
				continue: "Continue",
			},
			"check-email": {
				title: "Check your email",
				description: "We sent a verification link to ",
				"enter-code": "Enter code manually",
				"back-to-login": "Back to log in",
			},
		},
		modals: {
			addAvatar: {
				description: "<underline>Klicken zum Hochladen</underline> oder ziehen und ablegen",
				fileSize: "Maximale Dateigröße ist 3 MB",
				title: "Datei hochladen",
			},
			deleteRole: {
				title: "Rolle löschen",
			},
			deleteUser: {
				title: "Benutzer löschen",
			},
			editUser: {
				rolePlaceholder: "Rollen auswählen",
				title: "Benutzer bearbeiten",
			},
		},
		twoFA: {
			code: "Code",
			cta: "Bestätigen",
			title: "2FA-Code eingeben",
		},
		users: {
			title: "Benutzer",
			search: "Benutzer suchen",
		},
		userRoles: {
			title: "Rollen",
			button: "Benutzerrollen",
			modal: {
				create: {
					permissionPlaceholder: "Berechtigungen auswählen",
					title: "Rolle erstellen",
				},
				edit: {
					title: "Rolle aktualisieren",
				},
			},
			placeholders: {
				codePlaceholder: "Eingeben",
				namePlaceholder: "Name eingeben",
			},
		},
	},
};
