import { FunctionComponent } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as Dot } from "@assets/icons/sidebar/dot.svg";

import { classNames } from "@/utils/classNames";
import { SidenavItem } from "@/constants/sidenav.config";

import { ROUTE_CONFIG } from "@/routes/config";

import { SvgIcon } from "../Icon/SvgIcon";

interface SidenavItemProps extends SidenavItem {
	// isAdmin: boolean;
	children?: JSX.Element | JSX.Element[];
	testId?: string;
	companyId?: number;
}

export const SidebarItem: FunctionComponent<SidenavItemProps> = ({
	path,
	strictPath,
	icon,
	iconActive,
	onClick,
	// isAdmin,
	children,
	testId,
	type,
	companyId,
}) => {
	const location = useLocation();
	const isCompaniesPage = location.pathname === ROUTE_CONFIG.COMPANIES;

	const matchCompanyId = location.pathname.match(new RegExp(`${ROUTE_CONFIG.COMPANIES}/(\\d+)`));

	const isMyCompanyPage = matchCompanyId?.[1] === String(companyId);

	let match: boolean;

	if (strictPath) {
		match = location.pathname === path;
	} else {
		if (path === ROUTE_CONFIG.COMPANIES) {
			match = isCompaniesPage;
		} else if (matchCompanyId && path === `${ROUTE_CONFIG.COMPANIES}/${companyId}`) {
			match = isMyCompanyPage;
		} else {
			match = location.pathname.includes(path);
		}
	}

	const isActive = !!match as boolean;

	const getClassName = (isActive: boolean) => {
		const itemClassNames = isActive
			? classNames("rounded-[12px] bg-gray-200 text-gray-900")
			: classNames("text-gray-700 ");

		return classNames(
			"mb-1 w-[48px] h-[48px] flex items-center justify-center px-2",
			itemClassNames
		);
	};

	const getIcon = (isActive: boolean) => {
		const currentIcon = isActive && iconActive ? iconActive : icon;

		return typeof currentIcon === "string" ? (
			<img
				alt="icon"
				className={classNames(
					`w-[24px] rounded`,
					type === "logo" ? "bg-gray-700" : "",
					type === "icon" ? "invert" : ""
				)}
				src={currentIcon}
			/>
		) : (
			currentIcon
		);
	};

	return (
		<NavLink className={getClassName(isActive)} data-test={testId} to={path} onClick={onClick}>
			<div className="flex items-center justify-center">
				{icon && <div className="w-[24px]">{getIcon(isActive)}</div>}
				{type === "bullet" && isActive ? (
					<div className="w-[16px]">
						<SvgIcon
							className={classNames(
								"w-[32px] h-[32px] ",
								isActive ? "text-primary-blue" : "text-gray-700"
							)}
							svgIcon={Dot}
						/>
					</div>
				) : (
					<></>
				)}
				{/* <span className="text-sm font-medium">{title}</span> */}
			</div>
			{children && <div>{children}</div>}
		</NavLink>
	);
};
